import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Popup from 'reactjs-popup'
import ReactHtmlParser from 'react-html-parser'
import { pathOr } from 'ramda'



/* There are two Add Profile buttons and each has its own styling */
/* These buttons render on the Profiles page, NOT inside the Add Profile Popup */
const profileButton = (patient) => {
  if (patient === undefined) {
/* This is the Blue button for when there is no patient */
    return (
      <button className="btn btn-large btn-primary">
        <FontAwesome className="mr1" name="user" ></FontAwesome>
        Add Profile
      </button>
    )
  } else {
/* This is the grey button that shows along with other buttons when there is at least one patient */
    return (
      <button className="btn btn-large">
        <FontAwesome className="mr1" name="plus" ></FontAwesome>
        Add Profile
      </button>
    )
  }
}

//const getSearchPatient = (pharmacy, regID) =>
  //fetch(process.env.REACT_APP_API + '/' + 'grx_womensinternational' + '/getPatByReg/' + regID, {
    //headers: {
      //'Content-type': 'application/json',
      //Authorization: `Bearer ${process.env.REACT_APP_JWT}`,
      //id: regID
    //}
  //})

class AddProfile extends Component {
  render() {
    const props = this.props

    return(

      <Popup className="br3"
              trigger={ profileButton(props.validate.patient) }
              modal
              closeOnDocumentClick
      >

      {close => (
        <form className="offset1 ng-pristine ng-invalid ng-invalid-required"
              onSubmit={ props.submit(close, props.addProfile, props.pharmacy) } >

          <div style={{ width: 650 }} className="modal fade ng-scope in"><div className="modal-header"><h4>Add Profile</h4></div>
            <div className="modal-body">
              <div ng-bind-html="linkPatientTop" ng-show="pharmacy.linkPatientTop" className="ng-binding ng-hide" />
              <div className="ng-isolate-scope"><div className="growl-container growl-fixed top-right" ng-class="wrapperClasses()">
                </div><h6>{ReactHtmlParser(props.pharmacyDetail.linkPatientTop)}</h6></div>
              <div className="alert"><h4>All fields are required.</h4></div>

                <div className="row">
                  <div className="span2 offset1">
                    <label style={{ fontSize: 16 }} >First Name</label>
                    <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span2 ng-pristine ng-invalid ng-invalid-required" type="text" value={ pathOr('', ['addProfile', 'first_name'], props) }
                           onChange={props.addProfileFirstName} autoFocus autoComplete="new-password"   />
                  </div>
                  <div className="span3">
                    <label style={{ fontSize: 16 }} >Last Name</label>
                    <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span2 ng-pristine ng-invalid ng-invalid-required" type="text" value={ pathOr('', ['addProfile', 'last_name'], props) }
                           onChange={props.addProfileLastName} autoComplete="new-password"   />
                  </div>
                </div>
                <div className="row">
                  <div className="span2 offset1">
                    <label style={{ fontSize: 16 }} >Birth Month</label>
                    <select style={{ fontSize: 16 }}
                            className="span2 ng-pristine ng-valid"
                            value={ pathOr('', ['addProfile', 'birth_month'], props) }
                            onChange={props.addProfileBirthMonth} >
                      <option value={12}>Select</option>
                      <option value={0}>January</option>
                      <option value={1}>February</option>
                      <option value={2}>March</option>
                      <option value={3}>April</option>
                      <option value={4}>May</option>
                      <option value={5}>June</option>
                      <option value={6}>July</option>
                      <option value={7}>August</option>
                      <option value={8}>September</option>
                      <option value={9}>October</option>
                      <option value={10}>November</option>
                      <option value={11}>December</option>
                    </select>
                  </div>
                  <div className="span2">
                    <label style={{ fontSize: 16 }} >Birth Year (ex: YYYY)</label>
                    <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span2 ng-pristine ng-valid" type="text" value={ pathOr('', ['addProfile', 'birth_year'], props) }
                           onChange={props.addProfileBirthYear} autoComplete="new-password" max-length={4} />
                  </div>
                </div>
                <div className="row">
                  <div className="span2 offset1">
                    <label style={{ fontSize: 16 }}  className="ng-binding">{props.pharmacyDetail.ssn4_label}</label>
                    <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span2 ng-pristine ng-valid" type="text" value={ pathOr('', ['addProfile', 'ssn4'], props) }
                           onChange={props.addProfileSSN4} autoComplete="new-password" />
                  </div>
                  <div className="span2">
                    <label style={{ fontSize: 16 }} >ZipCode</label>
                    <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span2 ng-pristine ng-valid" type="text" value={ pathOr('', ['addProfile', 'zip'], props) }
                           onChange={props.addProfileZip}  autoComplete="new-password"/>
                  </div>

                </div>

            </div>
            <div className="modal-footer">
              <button className="btn btn-primary">Add Profile</button>
              <button className="btn btn-warning"
                 onClick={ () => { close() } }  >Cancel</button>
            </div>
          </div>
        </form>
      )}

      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
    pharmacyDetail: state.pharmacyDetail,
    addProfile: state.addProfile,
    validate: state.validate
})
const mapActionsToProps = (dispatch) => ({
  submit: (close, profile, pharmacy) => (e) => {
    e.preventDefault()
    var profileKey = profile.last_name + ":" + profile.first_name + ":" + profile.zip + ":" + profile.birth_month + ":" + profile.birth_year + ":" + profile.ssn4
    profile.key = profileKey.replace(/\s/g, '')
    profile.updateUser = true

    dispatch({ type: 'ADD_PROFILE', payload: profile })
    dispatch({ type: 'ADD_PROFILE_KEY', payload: profile.key })
    //dispatch({ type: 'SET_SEARCH_ID', payload: '35999'})
    //console.log('mATP')
    close()
  },
  addProfileFirstName: (e) => dispatch({ type: 'PROFILE_FIRST_NAME', payload: e.target.value }),
  addProfileLastName: (e) => dispatch({ type: 'PROFILE_LAST_NAME', payload: e.target.value }),
  addProfileBirthMonth: (e) => dispatch({ type: 'PROFILE_BIRTH_MONTH', payload: e.target.value }),
  addProfileBirthYear: (e) => dispatch({ type: 'PROFILE_BIRTH_YEAR', payload: e.target.value }),
  addProfileSSN4: (e) => dispatch({ type: 'PROFILE_SSN4', payload: e.target.value }),
  addProfileZip: (e) => dispatch({ type: 'PROFILE_ZIP', payload: e.target.value })
})
const connector = connect(mapStateToProps, mapActionsToProps)


export default connector(AddProfile)
