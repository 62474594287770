import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import '../css/bootstrap-responsive.css'
import '../css/landing.css'
import Header from '../components/header'
import RegisterTextField from '../components/registerText'
import BasicButton from '../components/basic-button'
import { pathOr, omit, slice } from 'ramda'
//import { validateLoginData } from '../functions'



const registerUser = (newUser, pharmacy) => {
  const index = pharmacy.indexOf('_')
  const newPharmacy = slice(index + 1, 99, pharmacy)

  //console.log('registerUser pharmacy is, ', newPharmacy)
  newUser.type = 'user'
  newUser._id = 'org.couchdb.user:' + newUser.name
  newUser.roles = ["patient", `${newPharmacy}`]
  newUser.patient = []

  fetch(process.env.REACT_APP_API + '/signup', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    },
    method: 'PUT',
    body: JSON.stringify( omit(['confirmPassword'], newUser) )
  })//.then(console.log("registerUser"))
}


const checkForDupeUser =  (user) =>
  fetch(process.env.REACT_APP_API + '/cp-users/' + user.name, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    }
  })//.then(console.log("checkForDupeUser"))

const usernameTest = (username) => {
  let regExUser = /^[a-zA-Z0-9\-_]{6,32}$/

  return ( !regExUser.test(username)
    ? <div className="alert alert-error"  style={{marginTop: 5}}>
        <em>Username</em> must have at least 6 characters with no spaces.
      </div>
    : <div><em></em></div> )
}

const passwordTest = (password) => {
  let regExPassword = /^[a-zA-Z0-9\S-_]{6,32}$/

  return ( !regExPassword.test(password)
    ? <div className="alert alert-error"  style={{marginTop: 5}}>
        <em>Password</em> must have at least 6 characters with no spaces.
      </div>
    : <div><em></em></div> )

}

const passwordsMatchTest = (pass1, pass2) => {
  return (pass1 !== pass2)
    ? <div className="alert alert-error ng-hide" ><h4 className='red' >Passwords must match...!</h4></div>
                           : <div><h4></h4></div>
}

class Register extends Component {
  render() {
    const props = this.props
    localStorage.clear();

    return (
      <div>
        <Header />

        <div className="container ng-scope" >
          <div className="span6 offset3" >


            <form className="form-horizontal ng-pristine ng-invalid ng-invalid-required"
                  onSubmit={ props.submit(props.validate, props.pharmacy, props.history) } >

              <div className="pt2" >
                <h3 className="fw4" >Register</h3>
              </div>

{/*  Not sure if this alert needs to be here...
              <div className="alert alert-error ng-binding ng-hide" ng-show="error">
                <strong>Error</strong>
              </div>
*/}

              <div className="control-group" >
                <label className="control-label">First and Last Name</label>
                <div className="controls">
                  <RegisterTextField
                             placeholder='Your full name'
                             type='text'
                             value={ pathOr('', ['validate', 'fullname'], props) }
                             onChange={props.validateFullname}
                             optional={false}
                             width='50'
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Email</label>
                <div className="controls">
                  <RegisterTextField
                               placeholder='Email'
                               type='text'
                               keyboardType='email-address'
                               value={ pathOr('', ['validate', 'email'], props) }
                               onChange={props.validateEmail}
                               optional={false}
                               width='50'
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">Username</label>
                  <div className="controls">
                    <RegisterTextField
                               placeholder='required'
                               type='text'
                               value={ pathOr('', ['validate', 'name'], props) }
                               onChange={props.validateUsername}
                               optional={false}
                               width='50'
                    />
                  </div>
                </div>

                <div>
                  { usernameTest(props.validate.name) }
                </div>

                <div className="control-group">
                  <label className="control-label">Password</label>
                  <div className="controls">
                    <RegisterTextField
                               placeholder='Password'
                               type='password'
                               value={ pathOr('', ['validate', 'password'], props) }
                               onChange={props.validatePassword}
                               optional={false}
                               width='50'
                    />

                    <div>
                      { passwordTest(props.validate.password) }
                    </div>

                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">Confirm password</label>
                  <div className="controls">
                    <RegisterTextField
                               placeholder='Confirm Password'
                               type='password'
                               value={ pathOr('', ['validate', 'confirmPassword'], props) }
                               onChange={props.confirmPassword}
                               optional={false}
                               width='50'
                    />
                  </div>
                </div>

              <div>
                { passwordsMatchTest(props.validate.password, props.validate.confirmPassword) }
              </div>

              <div className='mt4' >
                <BasicButton style={LoginButton} >
                  <FontAwesome className="ph1" name="lock" size="lg" ></FontAwesome>
                  Register
                </BasicButton>


                <span className="ph1"></span>

                <button style={ChangeButton}
                   className="fw5 white f6 pv2 no-underline bg-animate hover-black ba pl2 pr2"
                   onClick={ e => props.history.goBack() }
                >
                  <FontAwesome className="ph1" name="ban" size="lg" ></FontAwesome>
                  Cancel </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    )
  }
}

const LoginButton = {
    backgroundColor: '#0044cc',
    borderRadius: '6px',
    fontSize: '14px',
    opacity: 0.65
  }

const ChangeButton = {
    backgroundColor: '#faa732',
    borderRadius: '6px',
    fontSize: '14px'
  }


const mapStateToProps = (state) => ({
  pharmacyDetail: state.pharmacyDetail,
  validate: state.validate,
  pharmacy: state.pharmacy
})
const mapActionsToProps = (dispatch) => ({
  submit: (registerData, pharmacy, history) => (e) => {
    e.preventDefault()
    let regExTest = /^[a-zA-Z0-9\S-_]{6,32}$/

    if (registerData.password !== registerData.confirmPassword) {
      return alert('Passwords do no match!  Please rekey both password fields.')
    } else if ( !regExTest.test(registerData.password) )  {
      return alert('Password does not meet requirements.')
    } else if ( !regExTest.test(registerData.name) ) {
      return alert('Username does not meet requirements.')
    }

    checkForDupeUser(registerData)
       .then(res => res.json())
       .then(user => {
         if (registerData.name === user.name) {
           alert('User is taken.  Please enter another user name')
         } else {
           //console.log(registerData)
           registerUser(registerData, pharmacy)
           alert('Your profile has been created')
           history.push('/')
         }
       })
  },
  validateFullname: (e) => dispatch({ type: 'VALIDATE_FULLNAME', payload: e.target.value }),
  validateEmail: (e) => dispatch({ type: 'VALIDATE_EMAIL', payload: e.target.value }),
  validateUsername: (e) => dispatch({ type: 'VALIDATE_USERNAME', payload: e.target.value }),
  validatePassword: (e) => dispatch({ type: 'VALIDATE_PASSWORD', payload: e.target.value }),
  confirmPassword: (e) => dispatch({ type: 'CONFIRM_PASSWORD', payload: e.target.value })

})
const connector = connect(mapStateToProps, mapActionsToProps)


export default connector(Register)
