import React from 'react'
import PropTypes from 'prop-types'


const RegisterTextField = (props) => {
  const width = props.width ? `w-${props.width}` : 'w-80'

  return (
    <div style={ blockDisplay } className='pb3' >
      <label className='f5 mr3 mt2'>
        {props.label}
        {props.optional }
      </label>
      <small>{props.help}</small>

      <input  id={props.label}
              style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}
              className={`input-reset ba b--black-20 pa2 br2 ${width}`}
              type={props.type}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
      />
    </div>
  )
}


const blockDisplay = {
  display: 'block',
  width: 700
}


RegisterTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  help: PropTypes.string,
  width: PropTypes.string
}

export default RegisterTextField
