import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../components/header'
import { /*map,*/ pathOr} from 'ramda'

const href = window.location.href
const chParam = href.split("?").pop()

const getQuestion = (pharmacy, pharmKey) =>
  fetch(process.env.REACT_APP_API + '/' + pharmacy + '/question/' + chParam.slice(8, chParam.length - 3), {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    }
  })//.then(console.log('getUpdatedUser')).then(console.log(userID))


    const debug = (props) => {
      console.log(props)
      console.log(Date.parse(props.question.updated_at) + (259200000))
      console.log(Date.parse(new Date()))
      console.log(Date.parse(props.question.updated_at) + (259200000) > Date.parse(new Date()))
    }


class Questions extends Component {
  componentDidMount() {
    getQuestion(this.props.pharmacy, 'junk')
      .then(res => res.json())
      .then(question => this.props.setQuestion(question.rows[0].value) )
      //.then(question => console.log(question) )
  }

  render() {
    const props = this.props

    return (
      <div>
        <Header />
        { props.question.email === 'jlloyd@carepoint.com'
        ?  <button className="btn ml1" onClick={ e => debug(props)}>Debug</button>
        : <p/>
      }
        <div className="sans-serif ph7" >
          <div className="ph6" >
            <div className="pt2 pb2" >
              <h2 className="fw4" >Pharmacist Response:</h2>
            </div>
               <div className="pt2 pb2" >
                <p>Your Question - </p>
                <p>{props.question.question}</p>
                </div>

                <p>Pharmacist Answer - </p>
                <form onSubmit={ props.submit(props.pharmacy, props.question) } >
                    <textarea
                       style={{ fontSize: 16, height: 150, paddingTop: 15, paddingBottom: 15 }}
                       className="span6 ng-pristine ng-valid"
                       type="textarea"
                       value={ pathOr('', ['question', 'answer'], props) }
                       onChange={props.setAnswer}
                       disabled={props.question.answered_flag} />
                     <div>


                  <span className="ph0"></span>
                </div>
              </form>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  question: state.question,
  pharmacy: state.pharmacy,
})

const mapActionsToProps = (dispatch) => ({
  submit: (pharmacy, question) => (e) => {
    e.preventDefault()
    if (question.answered_flag) {

    } else {

    }

  },
  setQuestion: (question) => dispatch({ type: 'SET_QUESTION', payload: question }),
  setAnswer: (e) => dispatch({ type: 'SET_ANSWER', payload: e.target.value }),
  setAnsweredFlag: (e) => dispatch({ type: 'SET_ANSWERED_FLAG', payload: true }),
  setExpiredFlag: (e) => dispatch({ type: 'SET_EXPIRED_FLAG', payload: e.target.value })
})

const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(Questions)
