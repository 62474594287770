import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
//import { PersistGate } from 'redux-persist/lib/integration/react'
import 'tachyons/css/tachyons.css'
import 'font-awesome/css/font-awesome.min.css'
import App from './App'
import store from './store'
//import persistor from './store'

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
)
