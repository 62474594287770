import React from 'react'
import ReactHtmlParser from 'react-html-parser'


const profileTopExists = (top) => {
  return ( (top !== undefined)
    ? ReactHtmlParser(top)
    : <div></div>
  )
}

const profileLeftExists = (left) => {
  return ( (left !== undefined)
    ? ReactHtmlParser(left)
    : <div></div>
  )
}


export { profileTopExists, profileLeftExists }
