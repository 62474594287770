import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Header from '../components/header'
import TextField from '../components/input-text'
import { /*map,*/ pathOr, slice, takeWhile } from 'ramda'

const crypto = require('crypto')
const href = window.location.href
const href2 = href.substring(7)
var href3 = href2.substring(0, href2.indexOf('.'))

const index = href.indexOf('/')
const newHref = slice(index + 2, 100, href)
var pharmacyHref = takeWhile(x => x !== '.', newHref)

const validateLoginData = (loginData) => fetch(process.env.REACT_APP_API + '/changepassword', {
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_JWT}`
  },
  method: 'POST',
  body: JSON.stringify(loginData)
})//.then(console.log("validateLoginData"))

const updateCode = (user) => fetch(process.env.REACT_APP_API + '/updateCode', {
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_JWT}`
  },
  method: 'PUT',
  body: JSON.stringify(user)
})//.then(console.log("updateCode"))

//body: 'Your user name is: ' + userName + '<p><a href= ' + sendLink + '>Click this link to reset your password.</a>',
//body: '<h5>Refill Request</h5><p><Your user name is: ' + userName + '<p><a href= ' + sendLink + '>Click to Reset your password</a><p>Thank you for supporting our application, please let us know if you have any questions or concerns<p>Thanks<p>The Team',

const sendEmail = (userID, userName, sendLink) =>
  fetch(
    process.env.REACT_APP_API + '/changePassEmail',
    { method: 'POST',
      headers: {'Content-type': 'application/json',
      //body: 'Your user name is: ' + userName + '<p><a href= ' + sendLink + '>Click this link to reset your password.</a>',
      body: '<div style="color:darkblue"><h1>Refill Request</h1><h2>(' + userName + ')</h2><p>We are sorry you forgot your password, in order to reset your password, we need you to click the link below.</p><p>' + sendLink + '<div class="yj6qo"></div><div class="adL"></div></div>',
      //body: '<p>'+sendLink+'</p>',
      subject: 'Reset Your Webrefill Password',
      userEmail: userID,
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`}
    })//.then(console.log("sendEmail"))


class Reset extends Component {

  render() {
    const props = this.props

    return (
      <div>
        <Header />

        <div className="sans-serif ph7" >
          <div className="ph6" >

            <div className="pt2 pb2" >
              <h2 className="fw4" >Forgot/Change Password</h2>
            </div>
            <div className="pt2 pb2" >
            <p>If you forgot your password, no problem, we can send you a reset code!</p>
            <p>Just provide the e-mail address you used to register the account and we will e-mail you a link that will help you reset your password:</p>
            </div>

            <form onSubmit={ props.submit(props.validate, props.history) } >
              <TextField label='Email Address'
                         placeholder='required'
                         type='text'
                         value={ pathOr('', ['validate', 'name'], props) }
                         onChange={props.validateUsername}
                         optional={false}
                         width='40'
              />

              <div>
                <button  style={ SubmitButton } className="fw5 white f6 pv2 no-underline ba pl3 pr3">
                  <FontAwesome className="ph1" name="lock" size="lg" ></FontAwesome>
                  Submit </button>
                <span className="ph0"></span>
                <a style={ CancelButton } className="fw5 white f6 pv2 no-underline ba pl3 pr3" href="/signin">
                  <FontAwesome className="ph1" name="placeholder" size="lg" ></FontAwesome>
                  Cancel </a>
                <span className="ph0"></span>
              </div>
            </form>

          </div>
        </div>
      </div>
    )
  }
}


const CancelButton = {
    backgroundColor: '#faa732',
    borderRadius: '6px',
    fontSize: '14px'
  }
const SubmitButton = {
    backgroundColor: '#0044cc',
    borderRadius: '6px',
    fontSize: '14px',
    opacity: 0.65
  }


const mapStateToProps = (state) => ({
  validate: state.validate,
  valUser: state.valUser,
  pharmacy: state.pharmacy
})

const mapActionsToProps = (dispatch) => ({
  submit: (loginData, history) => (e) => {
    e.preventDefault()
    localStorage.clear()
    validateLoginData(loginData)
      .then( res => res.json() )
      .then( user => {
        //var code = crypto.randomBytes(20).toString('hex')
        //console.log(href)
        //console.log(href2)
        //console.log(href3)

        //console.log(index)
        //console.log(newHref)
        //console.log(pharmacyHref)
        if (user.rows.length > 0 && loginData.name.toUpperCase() === user.rows[0].key) {
          for (var i = 0; i < user.rows.length; i++){
              for (var j = 0; j < user.rows[i].value.roles.length; j++){
                if (href3 === 'pethealth2'){href3 = 'pethealth'}
                if (href3 === 'rxplus2'){href3 = 'rxplus'}
                if (!href3){href3 = 'pethealth'}
                //console.log(user)
                //console.log(href3)
                //console.log(crypto)
                //console.log(crypto.randomBytes(20))
                //console.log(crypto.randomBytes(20).toString('hex'))
                if (user.rows[i].value.roles[j] === href3 || user.rows[i].value.roles[j] === pharmacyHref) {
                  user.rows[i].value.code = crypto.randomBytes(20).toString('hex')
                  updateCode(user.rows[i].value)
                  var sendLink = 'https:/' + href3 + '.refillrequest.com/resetPassword?code=%22' + user.rows[i].value.code + '%22'
                  sendEmail(user.rows[i].value.email, user.rows[i].value._id.split('org.couchdb.user:').pop(), sendLink)
                  user.rows[i].value.code = ''
                }
              }
            }
          alert('A link has been sent to the email on file')
        } else {
          alert('Unable to find an email that matches.')
        }
        history.push('/')
      })
  },
  setValUser: (allUsers) => dispatch({ type: 'SET_VALUSER', payload: allUsers }),
  validateUsername: (e) => dispatch({ type: 'VALIDATE_USERNAME', payload: e.target.value }),
  validatePassword: (e) => dispatch({ type: 'VALIDATE_PASSWORD', payload: e.target.value })
})

const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(Reset)
