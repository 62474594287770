import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Header2 from '../components/header2'
import { without, map, filter, contains, append, takeWhile, replace } from 'ramda'
import moment from 'moment'
import Popup1 from '../pages/profile/change'
import Popup2 from '../pages/profile/addProfile'
import Popup3 from '../pages/profile/history'
import Popup5 from '../pages/profile/checkOut'
import Popup6 from '../pages/profile/removeProfile'
import Popup7 from '../pages/profile/askPharmacist'
import { greetingCheck, profileTopExists, profileLeftExists,
          } from '../functions'

const curDate = new Date()
const expireDate = curDate.setFullYear(curDate.getFullYear() - 1)


var localLoad = 1;
var renderPat = 1;
var firstSort = 1;
var searchVar;
var updatePat = 0;
var changeCurPat = 0;

const getProfilePatient = (patient, pharmacy) =>
      fetch(process.env.REACT_APP_API + '/' + pharmacy + '/patient/' + patient, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT}`
        }
  })//.then(console.log('getProfilePatient'))

const getUpdatedUser = (userID) =>
  fetch(process.env.REACT_APP_API + '/getUser/' + userID, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    }
  })

const getPharmacy = (id) => fetch(process.env.REACT_APP_API + '/cp-pharmacies/' + id, {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_JWT}`
  }
})//.then(console.log('getUpdatedUser'))

const updateUserPatients = (user, newID, addProfile) => {
  let newUser = user
  newUser.patient = append(newID, user.patient)
  fetch(process.env.REACT_APP_API + '/updateUser/' + newUser.name, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    },
    method: 'PUT',
    body: JSON.stringify(newUser)
  })//.then(console.log('updateUserPatients'))
  localStorage.setItem('user', JSON.stringify(user))
}

const submitOrder = (order) =>
  fetch(process.env.REACT_APP_API + '/order', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    },
    method: 'PUT',
    body: JSON.stringify(order)
  })//.then(console.log('submitOrder'))

const submitQuestion = (question) =>
  fetch(process.env.REACT_APP_API + '/qs', {
    headers: {
      'Content-Type': 'application/json' ,
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    },
    method: 'PUT',
    body: JSON.stringify(question)
  })//.then(console.log('submitQuestion'))

const getAllOrders = (pharmacy, id) =>
  fetch(process.env.REACT_APP_API + '/' + pharmacy + '/' + 'orders', {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`,
      id: id
    },
  })//.then(console.log(id))

const getSearchPatient = (pharmacy, regID) =>
  fetch(process.env.REACT_APP_API + '/' + pharmacy + '/getPatByReg/' + regID, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`,
      id: regID
    }
  })

const btnStatusText = (status) => {
  return (status) ? "Ordered"
                  : "Add to Order"
}

const btnMedStateControl = (script, props) => {
  return (script.status) ? props.removeItem(script)
                         : props.setItem(script)
}

const pharmacyMessages = messages => {
  return map(message => <h5 key={Math.random()} className="fw4" >{message}</h5>, messages.split('\\r\\n') )
}

const listPatients = (patient) => {
  return ( (patient.first_name)
    ?
          <option key={patient._id} style={{marginTop: 2, borderBottom: '1px solid gray', borderRight: '1px solid gray'}}
                  value={ patient._id }
          >
            {patient.first_name} {patient.last_name}
          </option>
    :     <option key={0} className="media ng-scope" style={{marginTop: 2, borderBottom: '1px solid gray', borderRight: '1px solid gray'}} ng-repeat="patient in patients" ng-class="{active: false}">
          </option>
    )}

    const sortPats = (pats, curPatID) =>{
      var test;
      if (pats.length > 0 && pats[0]._id !== curPatID && firstSort === 1) {
        for (var i = 0; i < pats.length; i++){
          if(pats[i]._id === curPatID){
            test = pats[i]
            pats.splice(i,1)
            pats = pats.unshift(test)
          }
        }
      }
      firstSort = 0
      return(pats)
    }

const btnStatus = (status) => {
    return ( status ) ? "btn add_to_order" : "btn add_to_order.disabled"
  }

const debug = (props) => {
  //console.log(searchID)
  //props.removeSearchPatient()
  //console.log(props)
  //console.log(JSON.parse(localStorage.getItem('user')))

}

const patientExists = (props, profilePatients, patient) => {
const currentScripts = (patient.scripts) ? filter(script => script.refills_left > 0, patient.scripts) : ""
const resetScripts = (patient.scripts) ? patient.scripts : ""

  return ( ( patient._id && renderPat === 1)

    ?
      <div className="span7" ng-show="patients.length > 0">
        <div style={{marginBottom: 30}}>
          <div className="row" style={{padding: '0px 20px'}}>
            <div className="btn-toolbar">

              <div className="btn-group">
                <div className="btn-toolbar">
                  <div className="btn-group">

    {/* Popup1 is the Update Information button and Popup on the Profile page when patient exists */}
                    <Popup1></Popup1>
    {/* Popup2 is the Add Profile button and Popup on the Profile page when patient exists */}
                    <Popup2></Popup2>

                    {/*<button className="btn btn-large btn-primary" onClick={ e => featSoon()}><FontAwesome className="mr1" name="user" ></FontAwesome>Ask a Pharmacist</button>*/}
                    <Popup7></Popup7>
                    {/*{props.pharmacyDetail.askEmail ?<Popup7></Popup7> : <Popup8></Popup8>}*/}

                  </div>
                </div>

                <select className="btn btn-lg dropdown-toggle ng-binding"
                        onChange={ props.setCurrentPatient }
                >
                    {map(listPatients, sortPats(profilePatients,props.currentPatient._id))}
                </select>
                {/* Popup6 is the Remove Profile button and Popup on the Profile page */}
                <Popup6></Popup6>
              </div>

{/* Ask a Pharmacist button and modal; only shows if configured in Couchdb pharmacy doc */}

              <div key={props.patientOrderMeds.scriptno} className="row mr4">
                <span className="pull-right">
                  <h4>Items in Cart:
                    {props.patientOrderMeds.length
                      ? <strong className="ng-binding ml1">{props.patientOrderMeds.length}</strong>
                      : <strong className="ng-binding ml1">0</strong>
                    }
                    </h4>
                </span>
              </div>

              <div className="well ng-binding">
                <h4>
                  <FontAwesome className="mr2" name="shopping-cart" ></FontAwesome>
                  Current Order Status
                </h4>

                <div className="row">

                  ?<div>
                    <h5 className="mr2" style={{ paddingLeft: '20px' }} >Order Number
                      { props.curPatOrders[0]
                        ?' ' + props.curPatOrders[0].value.order_number + ' on ' + moment(props.curPatOrders[0].value.updated_at).format("MM-DD-YYYY")
                        :' N/A'
                      }
                      { props.curPatOrders[0]
                        ? <span className="label label-info ng-binding ml2">{props.curPatOrders[0].value.status_message}</span>
                        : <span className="label label-info ng-binding ml2">pending</span>
                      }
                    </h5>
                  </div>
                  :<div></div>
                </div>

                  <h5>Pharmacy Messages:</h5>
                    {props.curPatOrders[0]
                      ? pharmacyMessages( replace(/\"/g, '', JSON.stringify(props.curPatOrders[0].value.pharmacy_message)) )
                      : <h5></h5>
                    }
                  <br />
                <div className="clearfix" />
              </div>

              <div className="well">
                <div className="media">
                  <div className="media-body">
                    <div className="btn-group pull-right">

                      <Popup3></Popup3>
                      <Popup5></Popup5>

                    </div>
                    <div>
                      <button className="btn mr1"
                              onClick={ e => props.setAllMeds(currentScripts) }
                      >Select All</button>
                      |
                      <button className="btn ml1"
                              onClick={ e => props.resetMeds(resetScripts) }
                      >Clear All</button>

                      {props.validate.email === 'jlloyd@carepoint.com' || props.validate.email === 'travis@womensinternational.com'
                        ? <button className="btn ml1" onClick={ e => debug(props)}>Debug</button>
                        : <p/>
                    }

                    </div>
                    <ul className="nav nav-stacked" style={{marginTop: 5}}>

    {/* list of medications from patients Pharmacy record */}
                      { patient.scripts === undefined
                          ? <div className="mt3" ><strong>You have no current prescriptions</strong></div>
                          : (map(script => (script.refills_left >= 0) && (Date.parse(script.start_date) >= expireDate)

                              ?     <li key={script.scriptno} ng-repeat="script in patient.scripts" className="ng-scope">
                                    <div style={{padding: '5px 5px', border: '1px solid lightgray'}}>
                                      <div className="pull-right">
                                        <button className={ btnStatus(script.status) } ng-click="add(script)" ng-disabled="script.ordered"
                                                onClick={ e => btnMedStateControl(script, props) } >
                                          <FontAwesome className="mr1" name="plus" ></FontAwesome>
                                          { btnStatusText(script.status) }
                                        </button>
                                        {/* Checkmark when Medication is selected for Order */}
                                        {script.scriptno === filter(med => med.scriptno === script.scriptno, props.patientOrderMeds)
                                          ?  <button>
                                                <FontAwesome className="ml1" name="check" ></FontAwesome>
                                             </button>
                                          : <div></div>
                                        }

                                      </div>
                                      <h5 style={{margin: 0}}>
                                        <strong className="ng-binding">{script.drug_name}</strong>
                                      </h5>
                                      <div className="row">
                                        <h6 className="span4 mb0" style={{color: 'rgba(0,0,0,.7)'}}>
                                          Rx# <strong className="ng-binding">{script.scriptno}  </strong>
                                          Qty: <strong className="ng-binding">{script.quantity}  </strong>
                                          Refills: <strong className="ng-binding">{script.refills_left}  </strong>
                                          Last Filled: <strong className="ng-binding">{script.last_fill_date} </strong>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>

                              :   <li key={script.scriptno} ng-repeat="script in patient.scripts" className="ng-scope">

                                  </li>
                            , patient.scripts))  }
                    </ul>
                  </div>
                </div>
              </div>

              <div className="span4 ng-hide" ng-show="pharmacy.profileRight">
                <div ng-bind-html="profileRight" className="ng-binding" />
              </div>

            </div>
          </div>
        </div>
      </div>

    :
      <div></div>
  )}



{/* Functions with a lot of JSX to render are now located in the functions folder */}

class ProfilePage extends Component {
  constructor(props) {
    super(props)
    this.props.updateValidation(JSON.parse(localStorage.getItem('user')))
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {

    if (this.props.validate.patient) {
        map(pat => getProfilePatient(pat, without(['patient'], this.props.validate.roles) )
                    .then(res => res.json() )
                    .then(patient => this.props.setPatients(patient))
                    .catch( err => console.log(err) )
            , this.props.validate.patient)
        if(this.props.validate.patient[0]) {
          getProfilePatient(this.props.validate.patient[0], without(['patient'], this.props.validate.roles) )
            .then(res => res.json())
            .then(patient => this.props.setPatient(patient, this.props.patientOrder))
            .catch( err => console.log(err) )
        }
      localLoad = 0
    }

    if (localLoad === 1 && JSON.parse(localStorage.getItem('user')).patient) {
        map(pat => getProfilePatient(pat, without(['patient'], JSON.parse(localStorage.getItem('user')).roles) )
                    .then(res => res.json() )
                    .then(patient => this.props.setPatients(patient))
                    .then(this.props.updateValidation(JSON.parse(localStorage.getItem('user'))))
                    .catch( err => console.log(err) )
            , JSON.parse(localStorage.getItem('user')).patient)
      if (JSON.parse(localStorage.getItem('user')).patient[0]) {
        getProfilePatient(JSON.parse(localStorage.getItem('user')).patient[0], without(['patient'], JSON.parse(localStorage.getItem('user')).roles) )
          .then(res => res.json())
          .then(patient => this.props.setPatient(patient, this.props.patientOrder))
          .catch( err => console.log(err) )
        }
    }



    getPharmacy(this.props.pharmacy)
      .then(res => res.json() )
      .then(pharmacy => this.props.setPharmacy(pharmacy))
      .catch( err => console.log(err))

    getAllOrders(this.props.pharmacy)
      .then(res => res.json())
      .then(allOrders => this.props.setAllOrders(allOrders) )
      .then(allOrders => this.props.setCurPatOrders(allOrders, this.props.currentPatient._id))
      .then(allOrders => this.props.setMostRecentPatOrder(allOrders, this.props.currentPatient._id))
      .catch(err => {
        getAllOrders(this.props.pharmacy)
          .then(res => res.json())
          .catch( err => console.log(err))
      })

{/* setOrders updates profile}


{/* I do NOT want this to fire unless the User has either */}
{/* a) Not submitted a Profile */}
{/* b) submitted a Profile but does not have a patient attached to profile */}
    if (!this.props.currentPatient._id) {

    }

  }


  componentWillUpdate(nextProps, nextState) {
    console.log(nextProps.validate._rev)

    if (!nextProps.validate.patient){
      nextProps.validate.patient = []
    }
    this.updateData(nextProps,nextState);
  }

  updateData(nextProps, nextState) {
    {/*  */}
    if (nextProps.validate.patient){
      if (!nextProps.validate.patient[0]) {
        renderPat = 0
      } else {
        renderPat = 1
      }
    }


{/* 1 - Switching currentPatient from one patient to another */} //console.log(1)
    if (nextProps.currentPatientId._id && nextProps.currentPatientId._id !== nextProps.currentPatient._id &&
        contains(nextProps.currentPatientId._id, map(patient => patient._id, nextProps.profilePatients)) === true ) {

          //console.log('1')
      if(this.props.validate.roles) {
        getProfilePatient(nextProps.currentPatientId._id, without(['patient'], this.props.validate.roles) )
          .then(res => res.json())
          .then(patient => this.props.setPatient(patient, nextProps.patientOrder))
          .catch( err => console.log(err) )
      }
      if(!this.props.validate.roles) {
        getProfilePatient(nextProps.currentPatientId._id, without(['patient'], JSON.parse(localStorage.getItem('user')).roles))
          .then(res => res.json())
          .then(patient => this.props.setPatient(patient, nextProps.patientOrder))
          .catch( err => console.log(err) )
      }
    }

{/* 2 - Updating allOrders to new mostRecentOrder when currentPatient is updated */} //console.log(2)
    if (nextProps.currentPatient && nextProps.patientOrder.getAllOrders === true) {
      nextProps.patientOrder.getAllOrders = false
      getAllOrders(nextProps.pharmacy, nextProps.currentPatient._id)
        .then(res => res.json())
        .then(curPatOrders => this.props.setCurPatOrders(curPatOrders, nextProps.currentPatient._id))
        .catch( err => console.log(err) )
    }

{/* 3 - Submitting an order */} //console.log(3)
    if (nextProps.patientOrder.order_id === 0 && !nextProps.patientOrder.orderStatus) {
      //console.log('3')
      nextProps.patientOrder.orderStatus = 'ordered'
      nextProps.patientOrder.getAllOrders = true
      submitOrder(nextProps.patientOrder)
        .then(res => res.json())
        .catch( err => console.log(err) )
        //.then(order => console.log(' 3 - Order updated in Couchdb') )
    }

{/* 4 - After order is submitted, GET all patient orders for display */} //console.log(4)
    if (nextProps.patientOrder && nextProps.patientOrder.getAllOrders === true) {
      nextProps.patientOrder.getAllOrders = false
      getAllOrders(nextProps.pharmacy)
        .then(res => res.json())
        .then(allOrders => this.props.setAllOrders(allOrders) )
        .then(curPatOrders => this.props.setCurPatOrders(curPatOrders) )
        .then(curPatOrders => this.props.setCurPatOrders(curPatOrders, nextProps.currentPatient._id))
        .catch( err => console.log(err) )
    }

{/* 5 - Sets currentPatient to new patient */} //console.log(5)
{/* 6 - updateProfile is set === false in mapActionsToProps && setNewPatient */} //console.log(6)
    if (nextProps && nextProps.addProfile.addNewProfile === true && nextProps.addProfile.key) {
        searchVar = nextProps.addProfile.key.split(':')
        getSearchPatient(this.props.pharmacy, nextProps.addProfile.ssn4)
          .then(res => res.json() )
          .then(searchPatient => this.props.setSearchPatient(searchPatient.rows, searchVar) )
          .catch( err => console.log(err) )

          updatePat = 1
          this.props.removeProfileKey()
    }

      if (nextProps.searchPatient === -1){
        alert(`Add Profile data does not match any current patients in ${nextProps.pharmacyDetail._id} pharmacy.`)
        this.props.removeSearchPatient()
      }

      if (nextProps.searchPatient._id && contains(nextProps.searchPatient._id, map(patient => patient, nextProps.validate.patient)) === false) {
        this.props.updateUser(nextProps.searchPatient._id, nextProps.validate, nextProps.addProfile)
        getProfilePatient(nextProps.searchPatient._id, without(['patient'], nextProps.validate.roles) )
          .then(res => res.json())
          .then(patient => this.props.setNewPatient2(patient))
          .catch( err => console.log(err))

        this.props.removeSearchPatient()
        updatePat = 1
      }
  {/* 9 - Updates profilePatients State when a new patient is added to Users profile */} //console.log(9)
      if (nextProps && updatePat === 1
        && contains(nextProps.currentPatient._id, map(patient => patient._id, nextProps.profilePatients)) === false ) {
        this.props.setPatients( nextProps.currentPatient )
        if (this.props.validate.roles && this.props.validate.patient) {

        if(this.props.validate.patient[0]){
          getProfilePatient(this.props.validate.patient[0],
            without(['patient'], this.props.validate.roles))
            .then(res => res.json())
            .then(patient => this.props.setPatient(patient, nextProps.patientOrder))
            .catch(err => console.log(err))
        }
      }
        if (changeCurPat === 1) {
          getProfilePatient(this.props.validate.patient[0], without(['patient'], JSON.parse(localStorage.getItem('user')).roles) )
            .then(res => res.json())
            .then(patient => this.props.setPatient(patient, nextProps.patientOrder))
            .catch( err => console.log(err) )

            changeCurPat = 0
        }
        updatePat = 0
      }

  {/* 10 - Updates profilePatients State when a patient is removed from Users profile */} //console.log(10)
      if (nextProps && nextProps.addProfile.removeProfile === true && nextProps.profilePatients.length > nextProps.validate.patient.length ) {
        this.props.removePatient( nextProps.currentPatient, nextProps.profilePatients, nextProps.validate, nextProps.addProfile )
      }

  {/* 11 - Updates currentPatient when patient is removed from User Profile (should be called after removePatient() ) */} //console.log(11)
      if (nextProps.currentPatient._id && nextProps.profilePatients.length > 0 && contains(nextProps.currentPatient._id, map(patient => patient._id, nextProps.profilePatients)) === false ) {
        this.props.setPatient(nextProps.profilePatients[0], nextProps.patientOrder)
      }

{/* 12 - In order to reliably add and remove profiles, I need to GET user from _users when updated */} //console.log(12)
    if (nextProps.addProfile.updateUser === true || nextProps.addProfile.removeProfile === true) {
      getUpdatedUser(nextProps.validate.name)
        .then(res => res.json() )
        .then(updatedUser => {
          if ( nextProps.validate.newDocRev === parseInt(takeWhile(doc => doc !== '-', updatedUser._rev), 10) ) {
            nextProps.addProfile.updateUser = false
            nextProps.addProfile.removeProfile = false
            this.props.updateValidation(updatedUser)
            this.props.resetProfile()
          } else {
            getUpdatedUser(nextProps.validate.name)
          }
        }
      )
      .catch( err => console.log(err))
    }

  {/* 13 - Submitting a question */} //console.log(13)
      if (nextProps.patientQuestion.question) {
        submitQuestion(nextProps.patientQuestion)
          .then(res => res.json())
          .catch( err => console.log(err) )
      }

  }

  render() {
    const props = this.props
    return (
      <div>

        <div>
          <Header2  />
        </div>

        <div className="container ng-isolate-scope">
          <div className="growl-container growl-fixed top-right" ng-class="wrapperClasses()">
          </div>
        </div>

        <div className="ng-scope">
          <div className="container ng-scope">
            <div className="span7" ng-show="patients.length > 0">
            </div>

            {/* Custom HTML for Pharmacy */}
            <div className="row" ng-show="pharmacy.profileTop">
              { profileTopExists(props.pharmacyDetail.profileTop) }
            </div>
            <div className="row">
              <div className="span4" ng-show="pharmacy.profileLeft">
                 { profileLeftExists(props.pharmacyDetail.profileLeft) }
              </div>
              <div>
{/* If User has no patients in profile */}
                { greetingCheck(props.pharmacyDetail, props.validate.patient) }
              </div>
                { patientExists(props, props.profilePatients, props.currentPatient) }
{/* Status is just for changing state to cause componentWillUpdate to fire */}
                <p className='white' >{props.status}</p>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  pharmacyDetail: state.pharmacyDetail,
  validate: state.validate,
  profilePatients: state.profilePatients,
  addProfile: state.addProfile,
  pharmacy: state.pharmacy,
  currentPatientId: state.currentPatientId,
  currentPatient: state.currentPatient,
  allPatients: state.allPatients,
  patientOrder: state.patientOrder,
  patientOrderMeds: state.patientOrderMeds,
  status: state.status,
  allOrders: state.allOrders,
  curPatOrders: state.curPatOrders,
  mostRecentPatOrder: state.mostRecentPatOrder,
  patientQuestion: state.patientQuestion,
  searchPatient: state.searchPatient,
  searchID: state.searchID
})
const mapActionsToProps = (dispatch) => ({

  setPatients: (patient) => dispatch({ type: 'SET_PATIENTS', payload: patient }),
  setPharmacy: (pharmacy) => dispatch({ type: 'SET_PHARMACY', payload: pharmacy }),

  setPatient: (patient, patientOrder) => {
    patientOrder.getAllOrders = true

    dispatch({ type: 'SET_PATIENT', payload: patient })
    dispatch({ type: 'RESET_ORDERS' })
    dispatch({ type: 'RESET_MEDS' })
  },
  setNewPatient: (patient, addProfile) => {
    if (addProfile.addNewProfile === true) {
      addProfile.addNewProfile = false

      dispatch({ type: 'SET_PATIENT', payload: patient})
      dispatch({ type: 'RESET_ORDERS' })
      dispatch({ type: 'RESET_MEDS' })
      dispatch({ type: 'RESET_MOST_RECENT_PAT_ORDER' })
    }
  },
  setNewPatient2: (patient) => {
      dispatch({ type: 'SET_PATIENT', payload: patient})
      dispatch({ type: 'RESET_ORDERS' })
      dispatch({ type: 'RESET_MEDS' })
      dispatch({ type: 'RESET_MOST_RECENT_PAT_ORDER' })
    },
  updateUser: (newPatientID, validate, addProfile) => {
{/* update _users table in Couch with new patient */}
    validate.newDocRev = parseInt(takeWhile(doc => doc !== '-', validate._rev), 10) + 1
    updateUserPatients(validate, newPatientID, addProfile)
  },
  setCurrentPatient: (e) => dispatch({ type: 'SET_CURRENT_PATIENT_ID', payload: e.target.value }),
  setCurrentPatientNew: (newPatient) => {console.log('Here = ' + newPatient)},
  setAllPatients: (allPatients) => dispatch({ type: 'SET_ALL_PATIENTS', payload: allPatients }),
  setSearchPatient: (searchPatient, searchVar) => {
      searchVar[3] = parseInt(searchVar[3]) + 1
      var pat = []
      var updated;
      for(var i = 0; i < searchPatient.length; i++){
        if(searchVar[0] === searchPatient[i].value.last_name.toLowerCase()
           && searchVar[1] === searchPatient[i].value.first_name.toLowerCase()
           && searchVar[2] === searchPatient[i].value.home_address_zip
           && searchVar[3] === parseInt(searchPatient[i].value.birth_date.split('-')[1])
           && searchVar[4] === searchPatient[i].value.birth_date.split('-')[0]) {
             if(Date.parse(searchPatient[i].value.updated_at) > updated || !updated){
               pat = searchPatient[i].value
               updated = Date.parse(searchPatient[i].value.updated_at)
             }
           }
      }
      if(pat._id) {
        dispatch({ type: 'SET_SEARCH_PATIENTS', payload: pat})
        dispatch({ type: 'SET_PATIENT', payload: pat})
        dispatch({ type: 'SET_CURRENT_PATIENT_ID', payload: pat._id})
        changeCurPat = 1
        firstSort = 1
        pat = []
      } else {
        dispatch({ type: 'SET_SEARCH_PATIENTS', payload: -1})
      }
  },
  setAllOrders: (allOrders) => dispatch({ type: 'SET_ALL_ORDERS', payload: allOrders }),
  setCurPatOrders: (curPatOrders, patientID) => {
    let testVar = curPatOrders.rows.filter(orders => orders.value.patient_id === patientID)
    dispatch({ type: 'SET_ALL_PAT_ORDERS', payload: testVar })
  },
  setMostRecentPatOrder: (mostRecentPatOrder, patientID) => {
    let testVar2 = mostRecentPatOrder.rows.filter(orders => orders.value.patient_id === patientID)
    dispatch({ type: 'SET_MOST_RECENT_PAT_ORDER', payload: testVar2 })
  },
  setItem: (item) => {
    item.status = true
    item.ordered = true
    dispatch({ type: 'SET_ITEMS', payload: item })
  },
  removeItem: (item) => {
    item.status = false
    item.ordered = false
    dispatch({ type: 'REMOVE_MED', payload: item })

  },
  setAllMeds: (allScripts) => {
    map(script => (!script.status) ? (script.status = true, script.ordered = true)
                                            : script.status
      , allScripts)
    dispatch({ type: 'SET_ALL_ITEMS', payload: allScripts })
  },
  resetMeds: (allScripts) => {
    map(script => (script.status === true) ? (script.status = false, script.ordered = false)
                                            : script.status
      , allScripts)
    dispatch({ type: 'RESET_MEDS' })
  },
  updateValidation: (user) => dispatch({ type: 'SET_VALIDATION', payload: user }),
  removePatient: (patient, profilePatients, validate, addProfile) => {
    validate.newDocRev = parseInt(takeWhile(doc => doc !== '-', validate._rev), 10) + 1
    dispatch({ type: 'REMOVE_PATIENT', payload: patient })
  },
  removeProfileKey: () => dispatch({ type: 'REMOVE_PROFILE_KEY' }),
  removeSearchID: () => dispatch({ type: 'REMOVE_SEARCH_ID' }),
  resetProfile: () => dispatch({ type: 'RESET_PROFILE' }),
  removeSearchPatient: () => dispatch({ type: 'RESET_SEARCH'}),
  testAdd: (newUser) => dispatch({ type: 'SET_VALIDATION', payload: newUser })
})
const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(ProfilePage)
