import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../css/landing.css'
import '../css/bootstrap.css'
//import FontAwesome from 'react-fontawesome'
import ReactHtmlParser from 'react-html-parser'
//import htmlclean from 'htmlclean'
import Header from '../components/header'
import 'react-app-polyfill/ie11'


const getPharmacy = (id) => window.fetch(process.env.REACT_APP_API + '/cp-pharmacies/' + id, {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_JWT}`
  }
})//.then(console.log("getPharmacy"))

const isIE = /*@cc_on!@*/false || !!document.documentMode;

class LandingPage extends Component {
  componentDidMount() {

    if (this.props.pharmacy === []) {
      getPharmacy('test')
        .then(res => res.json() )
        .then(pharmacy => this.props.setPharmacy(pharmacy))
    } else {
      getPharmacy(this.props.pharmacy)
        .then(res => res.json() )
        .then(pharmacy => this.props.setPharmacy(pharmacy))
    }
  }


  render() {
    //console.log(isIE)

    const props = this.props
    if(!isIE){
      return(
        <div>

          <Header />

          <div className="ph6" >
            { ReactHtmlParser(props.pharmacyDetail.landingPage) }
          </div>

        </div>
      )
    } else {
      return(
        <div>
          This website is not currently supported on Internet Explorer. Please use Microsoft Edge, Chrome, or FireFox, or contact your pharmacy for additional assistance.
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  pharmacy: state.pharmacy,
  pharmacyDetail: state.pharmacyDetail,
  allUsers: state.allUsers
})
const mapActionsToProps = (dispatch) => ({
  setPharmacy: (pharmacy) => dispatch({ type: 'SET_PHARMACY', payload: pharmacy })
})

const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(LandingPage)
