import React from 'react'
import ReactHtmlParser from 'react-html-parser'
//import FontAwesome from 'react-fontawesome'
import Popup2 from '../pages/profile/addProfile'


const greetingCheck = (pharmacyDetail, patient) => {

// no patient, but greeting exists in database
  if ( (patient === undefined || !patient[0]) && pharmacyDetail.greeting !== undefined ) {
    //console.log('No Pat - Greeting')
    return (
      <div>
        { ReactHtmlParser(pharmacyDetail.greeting) }
        <Popup2></Popup2>
      </div>
    )
// no patient, AND greeting does not exist in database
} else if ( (patient === undefined || !patient[0]) && pharmacyDetail.greeting === undefined ) {
    //console.log('No Pat - No Greeting')
    return (
      <div className="span7 ng-hide" ng-hide="patients.length > 0">
        <div ng-bind-html="greeting" className="ng-binding" />
        <div ng-hide="pharmacy.greeting">
          <h3>Welcome to The Web Refill System</h3>
          <p>Now that you have registered your account, it is time to add personal profile information.</p>
          <h5>How?</h5>
          <p>For starters, please click on the Add Profile button just after this message.  Then enter your first name, last name, birth month, birth year, pharmacy account number and zip code.  Your pharmacy account number is located on your pharmacy invoice (to the right of your name).</p>
        </div>

        <Popup2></Popup2>

      </div>
    )
  }
    return <div></div>
}

export { greetingCheck }
