import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Header from '../components/header'
import TextField from '../components/input-text'
import { /*map,*/ pathOr, slice, takeWhile } from 'ramda'
import '../css/bootstrap.css'
import 'react-app-polyfill/ie11'

const href = window.location.href
///const href2 = href.substring(8)
//var href3 = href2.substring(0, href2.indexOf('.'))

const index = href.indexOf('/')
const newHref = slice(index + 2, 100, href)
var href3 = takeWhile(x => x !== '.', newHref)

if (href3 === 'pethealth2' || href3 === 'localhost:3000/profiles'){
  href3 = 'pethealth'
}

if (href3 === 'womensinternational2'){
  href3 = 'womensinternational'
}



const validateLoginData = (loginData) =>  fetch(process.env.REACT_APP_API + '/login', {
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_JWT}`
  },
  method: 'POST',
  body: JSON.stringify(loginData)
})//.then(console.log("validateLoginData"))

/*
const debug = (props) => {

}
*/

class Login extends Component {

  render() {
    const props = this.props

    return (
      <div>
        <Header />

        <div className="container ng-scope" >
          <div className="span6 offset3" >



            <form onSubmit={ props.submit(props.validate, props.history) } >
              <TextField label='Username'
                         placeholder='required'
                         type='text'
                         value={ pathOr('', ['validate', 'name'], props) }
                         onChange={props.validateUsername}
                         optional={false}
                         width='40'
              />
              <TextField label='Password'
                         placeholder='required'
                         type='password'
                         value={ pathOr('', ['validate', 'password'], props) }
                         onChange={props.validatePassword}
                         optional={false}
                         width='40'
              />

              <div>
                <button  style={ LoginButton } className="fw5 white f6 pv2 no-underline ba pl3 pr3">
                  <FontAwesome className="ph1" name="lock" size="lg" ></FontAwesome>
                  Login </button>
                <a style={ RegisterButton } className="fw5 white f6 pv2 no-underline ba pl2 pr2" href="/signup">
                  <FontAwesome className="ph1" name="pencil" size="lg" ></FontAwesome>
                  Register </a>
                <span className="ph0"></span>
                <a style={ ChangeButton } className="fw5 white f6 pv2 no-underline ba pl2 pr2" href="/reset">
                  <FontAwesome className="ph1" name="placeholder" size="lg" ></FontAwesome>
                  Forgot/Change Password </a>
                <span className="ph0"></span>
              </div>
            </form>

          </div>
        </div>
      </div>
    )
  }
}


const RegisterButton = {
    backgroundColor: '#49afcd',
    borderRadius: '6px',
    fontSize: '14px'
  }
const ChangeButton = {
    backgroundColor: '#faa732',
    borderRadius: '6px',
    fontSize: '14px'
  }
const LoginButton = {
    backgroundColor: '#0044cc',
    borderRadius: '6px',
    fontSize: '14px',
    opacity: 0.65
  }


const mapStateToProps = (state) => ({
  validate: state.validate,
  valUser: state.valUser,
  pharmacy: state.pharmacy
})

const mapActionsToProps = (dispatch) => ({
  submit: (loginData, history) => (e) => {
    e.preventDefault()
    localStorage.clear()
    validateLoginData(loginData)
      .then( res => res.json() )
      .then( user => {
        var matchStore = true
        if (user.roles){
          for (var i = 0; i < user.roles.length; i++){
            if (user.roles[i] === href3) {
              console.log(user.roles[i] + ' : ' + href3)
              matchStore = true
            }
          }
        }

        if (window.location.href === 'http://localhost:3000/signin') {
          matchStore = true
        }

        if ( loginData.name === user.name ) {
          console.log(loginData.name)
          if ( matchStore ) {
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('loginData', JSON.stringify(loginData))
          dispatch({ type: 'SET_VALIDATION', payload: user })
          history.push('/profiles')
        } else {
          alert('This user is tied to a different store')
        }
        } else {
          alert('Login incorrect, please try again')
        }
      })
  },
  setValUser: (allUsers) => dispatch({ type: 'SET_VALUSER', payload: allUsers }),
  validateUsername: (e) => dispatch({ type: 'VALIDATE_USERNAME', payload: e.target.value }),
  validatePassword: (e) => dispatch({ type: 'VALIDATE_PASSWORD', payload: e.target.value })
})

const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(Login)
