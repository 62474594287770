import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Header from '../components/header'
import TextField from '../components/input-text'
import { /*map,*/ omit, pathOr } from 'ramda'

//const crypto = require('crypto')
const chHref = window.location.href
const chParam = chHref.split("?").pop()
const chCode = {};

const validateLoginData = (code) => fetch(process.env.REACT_APP_API + '/userByCode', {
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_JWT}`
  },
  method: 'POST',
  body: JSON.stringify(code)
})//.then(console.log("validateLoginData"))

const updatePassword = (user) => fetch(process.env.REACT_APP_API + '/updatePassword', {
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_JWT}`
  },
  method: 'PUT',
  body: JSON.stringify(omit(['code'], user ))
})//.then(console.log("updatePassword"))

const passwordTest = (password) => {
    let regExPassword = /^[a-zA-Z0-9\S-_]{6,32}$/

    return ( !regExPassword.test(password)
      ? <div className="alert alert-error"  style={{marginTop: 5}}>
          <em>Password</em> must have at least 6 characters with no spaces.
        </div>
      : <div><em></em></div> )

  }

  const passwordsMatchTest = (pass1, pass2) => {
    return (pass1 !== pass2)
      ? <div className="alert alert-error ng-hide" ><h4 className='red' >Passwords must match...!</h4></div>
                             : <div><h4></h4></div>
  }


class Reset extends Component {

  render() {
    const props = this.props

    return (
      <div>
        <Header />

        <div className="sans-serif ph7" >
          <div className="ph6" >

            <div className="pt2 pb2" >
              <h2 className="fw4" >Forgot/Change Password</h2>
            </div>

            <form onSubmit={ props.submit(props.validate, props.history) } >
              <TextField label='New Password'
                         placeholder='Password'
                         type='password'
                         value={ pathOr('', ['validate', 'password'], props) }
                         onChange={props.validatePassword}
                         optional={false}
                         width='40'
              />
              <div>
                { passwordTest(props.validate.password) }
              </div>

              <TextField label='Confirm New Password'
                         placeholder='Confirm Password'
                         type='password'
                         //value={ pathOr('', ['validate', 'confirmpassword'], props) }
                         onChange={props.confirmPassword}
                         optional={false}
                         width='40'
              />
              <div>
                { passwordsMatchTest(props.validate.password, props.validate.confirmPassword) }
              </div>

              <div>
                <button  style={ SubmitButton } className="fw5 white f6 pv2 no-underline ba pl3 pr3">
                  <FontAwesome className="ph1" name="lock" size="lg" ></FontAwesome>
                  Submit </button>
                <span className="ph0"></span>
              </div>
            </form>

          </div>
        </div>
      </div>
    )
  }
}


/*
const CancelButton = {
    backgroundColor: '#faa732',
    borderRadius: '6px',
    fontSize: '14px'
  }
*/
const SubmitButton = {
    backgroundColor: '#0044cc',
    borderRadius: '6px',
    fontSize: '14px',
    opacity: 0.65
  }


const mapStateToProps = (state) => ({
  validate: state.validate,
  valUser: state.valUser,
  pharmacy: state.pharmacy
})

const mapActionsToProps = (dispatch) => ({
  submit: (loginData, history) => (e) => {
    e.preventDefault()
    localStorage.clear()
    chCode.code = chParam.slice(8, chParam.length - 3)
    validateLoginData(chCode)
      .then( res => res.json() )
      .then( user => {
        user.rows[0].value.password = loginData.password
        updatePassword(user.rows[0].value)
        alert('Your password has been reset.')
        history.push('/signin')
      })
  },
  setValUser: (allUsers) => dispatch({ type: 'SET_VALUSER', payload: allUsers }),
  validateUsername: (e) => dispatch({ type: 'VALIDATE_USERNAME', payload: e.target.value }),
  validatePassword: (e) => dispatch({ type: 'VALIDATE_PASSWORD', payload: e.target.value }),
  confirmPassword: (e) => dispatch({ type: 'CONFIRM_PASSWORD', payload: e.target.value })
})

const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(Reset)
