import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Popup from 'reactjs-popup'
import { without } from 'ramda'


const updateUserPatients = (newUser) => {
  fetch(process.env.REACT_APP_API + '/updateUser/' + newUser.name, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    },
    method: 'PUT',
    body: JSON.stringify(newUser)
  })//.then(console.log("updateUserPatients"))

  localStorage.setItem('user', JSON.stringify(newUser))
}

//const getUpdatedUser = (userID) =>
//  fetch(process.env.REACT_APP_API + '/getUser/' + userID, {
//    headers: {
//      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
//    }
//  })



class RemoveProfile extends Component {
  render() {
    const props = this.props

    return (

      <Popup trigger={ <button className="btn btn-large">
                          <FontAwesome name="trash" ></FontAwesome>
                       </button>
                     }
             modal
             closeOnDocumentClick
      >

        {close => (

            <div className="modal fade ng-scope in">
              <div className="modal-header">
                <h4>Remove Profile for:</h4>
              </div>
              <div className="modal-body">
                <h1>{props.currentPatient.first_name} {props.currentPatient.last_name}</h1>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary"
                        onClick={ e => props.removeProfile(close, props.currentPatient._id, props.validate, props.addProfile) }
                >Ok</button>
                <button className="btn btn-warning"
                        onClick={() => { close() }}
                >Cancel</button>
              </div>
            </div>
        )}

      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  profilePatients: state.profilePatients,
  currentPatient: state.currentPatient,
  validate: state.validate,
  addProfile: state.addProfile
})
const mapActionsToProps = (dispatch) => ({
  removeProfile: (close, patientId, validate, profile) => {
    var newUser = validate
    newUser.patient = without([patientId], validate.patient)
    profile.removeProfile = true

{/* update Couch for User without removed Patient */}
    updateUserPatients(newUser)
    close()
    setTimeout( () => dispatch({ type: 'SET_STATUS', payload: 1 }), 1 )
  }
})
const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(RemoveProfile)
