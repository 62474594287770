import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Header from '../components/header'
import { /*map,*/ pathOr } from 'ramda'

const href = window.location.href
const chParam = href.split("?").pop()

const getQuestion = (pharmacy, pharmKey) =>
  fetch(process.env.REACT_APP_API + '/' + pharmacy + '/question/' + chParam.slice(8, chParam.length - 3), {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    }
  })//.then(console.log('getUpdatedUser')).then(console.log(userID))

const updateQuestion = (pharmacy, question) =>
  fetch(process.env.REACT_APP_API + '/' + pharmacy + '/answer/' + question._id, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify(question)
  })//.then(console.log("updateProfilePatient"))

//body: 'Your user name is: ' + userName + '<p><a href= ' + sendLink + '>Click this link to reset your password.</a>',
//body: '<h5>Refill Request</h5><p><Your user name is: ' + userName + '<p><a href= ' + sendLink + '>Click to Reset your password</a><p>Thank you for supporting our application, please let us know if you have any questions or concerns<p>Thanks<p>The Team',

const sendEmail = (email, sendLink) =>
  fetch(
    process.env.REACT_APP_API + '/changePassEmail',
    { method: 'POST',
      headers: {'Content-type': 'application/json',
      body: 'Your question has been answered!<p><a href= ' + sendLink + '>Click this link to view your answer.</a><p>This answer will expire after 72 hours.',
      subject: 'Ask A Pharmacist',
      userEmail: email,
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`}
    })//.then(console.log("sendEmail"))

    const debug = (props) => {
      console.log(Date())
      console.log(props)
    }


class Questions extends Component {
  componentDidMount() {
    getQuestion(this.props.pharmacy, 'junk')
      .then(res => res.json())
      .then(question => this.props.setQuestion(question.rows[0].value) )
      //.then(question => console.log(question) )
  }

  render() {
    const props = this.props

    return (
      <div>
        <Header />
        { props.question.email === 'jlloyd@carepoint.com'
        ?  <button className="btn ml1" onClick={ e => debug(props)}>Debug</button>
        : <p/>
      }
        <div className="sans-serif ph7" >
          <div className="ph6" >
            <div className="pt2 pb2" >
              <h2 className="fw4" >Answer Patient Question:</h2>
            </div>
               <div className="pt2 pb2" >
                <p>Patient email - </p>
                <p>{props.question.email}</p>
                <p>Patient Question - </p>
                <p>{props.question.question}</p>
                </div>

                <form onSubmit={ props.submit(props.pharmacy, props.question) } >
                    <textarea
                       style={{ fontSize: 16, height: 150, paddingTop: 15, paddingBottom: 15 }}
                       className="span6 ng-pristine ng-valid"
                       type="textarea"
                       value={ pathOr('', ['question', 'answer'], props) }
                       onChange={props.setAnswer}
                       disabled={props.question.answered_flag} />
                     <div>

                  <button  style={ SubmitButton }
                    className="fw5 white f6 pv2 no-underline ba pl3 pr3">
                    <FontAwesome className="ph1" name="lock" size="lg" ></FontAwesome>
                    Submit </button>

                  <span className="ph0"></span>
                  <a style={ CancelButton } className="fw5 white f6 pv2 no-underline ba pl3 pr3" href="/signin">
                    <FontAwesome className="ph1" name="placeholder" size="lg" ></FontAwesome>
                    Cancel </a>


                  <span className="ph0"></span>
                </div>
              </form>
          </div>
        </div>
      </div>
    )
  }
}


const CancelButton = {
    backgroundColor: '#faa732',
    borderRadius: '6px',
    fontSize: '14px'
  }
const SubmitButton = {
    backgroundColor: '#0044cc',
    borderRadius: '6px',
    fontSize: '14px',
    opacity: 0.65
  }


const mapStateToProps = (state) => ({
  question: state.question,
  pharmacy: state.pharmacy,
})

const mapActionsToProps = (dispatch) => ({
  submit: (pharmacy, question) => (e) => {
    e.preventDefault()
    if (question.answered_flag) {
      alert('This question has already been answered and cannot be modified')
    } else {
      dispatch({ type: 'SET_ANSWERED_FLAG', payload: true })
      dispatch({ type: 'SET_UPDATED_AT', payload: new Date()})
      question.answered_flag = true
      question.updated_at = new Date()
      alert("Answer submitted")
      updateQuestion(pharmacy,question)
      sendEmail(question.email, 'https://' + pharmacy.substr(4) + '.refillrequest.com/answers?code="'+ question.patKey +'"')
    }

  },
  setQuestion: (question) => dispatch({ type: 'SET_QUESTION', payload: question }),
  setAnswer: (e) => dispatch({ type: 'SET_ANSWER', payload: e.target.value }),
  setAnsweredFlag: (e) => dispatch({ type: 'SET_ANSWERED_FLAG', payload: true }),
  setExpiredFlag: (e) => dispatch({ type: 'SET_EXPIRED_FLAG', payload: e.target.value })
})

const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(Questions)
