import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Popup from 'reactjs-popup'
import { pathOr } from 'ramda'


//const updateUserPatients = (newUser) => {
//  fetch(process.env.REACT_APP_API + '/updateUser/' + newUser.name, {
//    headers: {
//      'Content-Type': 'application/json',
//      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
//    },
//    method: 'PUT',
//    body: JSON.stringify(newUser)
//  })

//  localStorage.setItem('user', JSON.stringify(newUser))
//}

const changeEmail = (user) => {
  fetch(process.env.REACT_APP_API + '/updateEmail/', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
    },
    method: 'PUT',
    body: JSON.stringify(user)
  })//.then(console.log("changeEmail"))
}

//const getUpdatedUser = (userID) =>
//  fetch(process.env.REACT_APP_API + '/getUser/' + userID, {
//    headers: {
//      Authorization: `Bearer ${process.env.REACT_APP_JWT}`
//    }
//  })



class ChangeAct extends Component {
  render() {
    const props = this.props

    return (

      <Popup trigger={  <button className="btn">
                          <FontAwesome className="mr1" name="user" ></FontAwesome>
                          Account
                        </button>
                      }
             modal
             closeOnDocumentClick
      >

        {close => (
          <form onSubmit={
                  props.submit(close, props.validate, props.pharmacyDetail) }>
            <div className="modal fade ng-scope in">
              <div className="modal-header">
                <h4>Account Information</h4>
              </div>
              <div className="modal-body">
                <h5>Username - {this.props.validate.name}</h5>
                <h4>Email</h4>
                <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }} className="span4 ng-pristine ng-valid" type="text"
                  value={ pathOr('', ['validate', 'email'], props) }
                  onChange={props.validateEmail}/>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" >Change</button>
                <button className="btn btn-warning"
                        onClick={() => { close() }}
                >Close</button>
              </div>
            </div>
          </form>
        )}

      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  validate: state.validate,
})
const mapActionsToProps = (dispatch) => ({
  submit: (close, validate, pharmacy) => (e) => {
    e.preventDefault()
    changeEmail(validate,pharmacy)
    close()
  },
  validateEmail: (e) => dispatch({ type: 'VALIDATE_EMAIL', payload: e.target.value })
})
const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(ChangeAct)
