import React, { Component } from 'react'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import { map, replace } from 'ramda'

/*
const debug = () => {

  }
*/

/*
const medHistoryList = (script) => {
  return ( (script.refills_left === "0")

    ?     <li key={script.scriptno} ng-repeat="script in patient.scripts" className="ng-scope">
          <div style={{padding: '5px 5px', border: '1px solid lightgray'}}>

            <h5 style={{margin: 0}}>
              <strong className="ng-binding">{script.drug_name}</strong>
            </h5>
            <div className="row" style={{ padding: '0px 20px' }} >
              <h6>
                Rx# <strong className="ng-binding">{script.scriptno}  </strong>
                Qty: <strong className="ng-binding">{script.quantity}  </strong>
                Refills: <strong className="ng-binding">{script.refills_left}  </strong>
                Last Filled: <strong className="ng-binding">{script.last_fill_date} </strong>
              </h6>

            </div>
          </div>
        </li>

    :   <li key={script.scriptno} ng-repeat="script in patient.scripts" className="ng-scope">
          <div><strong>No Rx History to list</strong></div>
        </li>

  )
}
*/
const pharmacyMessages = messages => {
  return map(message => <h5 key={message} className="fw4" >{message}</h5>, messages.split('\\r\\n') )
}

class HistoryProfile extends Component {
  render() {
    const props = this.props

    return(

      <Popup trigger={ <button className="btn" ng-click="history(patient)">
        Order History</button>} modal closeOnDocumentClick >

       {close => (
         <div className="modal fade ng-scope in">
          <div className="modal-header">
            <h4>Order History</h4>
          </div>

          <div className="modal-body">
            <h6>
            <div>
              {/* ngRepeat: order in orders */}
              { map(order => order.value.order_number
                          ?     <h6 className="ng-binding">
                                  <button type="button" className="btn btn-primary btn-lg btn-block">
                                    <h6>Order # {order.value.order_number ? order.value.order_number : 'N/A'}
                                    <span className="label label-info ng-binding ml2">{order.value.orderStatus}</span></h6>
                                  </button>
                                  <div>
                                    { map(scripts => <h6 className="ng-binding">Rx {scripts.scriptno} - {scripts.drug_name} - QTY:{scripts.quantity}</h6>, order.value.items) }
                                  </div>
                                  <h5>Pharmacy Messages:</h5>
                                    {order.value.pharmacy_message
                                      ? pharmacyMessages( replace(/\"/g, '', JSON.stringify(order.value.pharmacy_message)) )
                                      : <h5></h5>
                                    }
                                </h6>
                          : <div>No Order History to Show</div>

                  , props.curPatOrders)
              }
            </div>
            </h6>
          </div>

           <div className="modal-footer">
             <button className="btn btn-warning"
                     onClick={() => { close() }}
             >Cancel</button>
           </div>
         </div>

       )}

      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  profilePatients: state.profilePatients,
  currentPatient: state.currentPatient,
  allOrders: state.allOrders,
  curPatOrders: state.curPatOrders
})

const connector = connect(mapStateToProps)

export default connector(HistoryProfile)
