import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Popup from 'reactjs-popup'
import { pathOr } from 'ramda'

var replyHead = 'Reply to: '

const crypto = require('crypto')
const href = window.location.href
const href2 = href.substring(7)
var href3 = href2.substring(0, href2.indexOf('.'))
const pharmKeyCode = crypto.randomBytes(20).toString('hex')
const pharmKey = 'PHARM' + pharmKeyCode
const patKeyCode= crypto.randomBytes(20).toString('hex')
const patKey = 'PAT' + patKeyCode
//var sendLink = 'https://' + href3 + '.refillrequest.com/questions?code="' + pharmKey + '"'

const buildQuestion = ( pharmacyDetail, currPatient, question ) => {
  var patientQuestion = {}

  patientQuestion.cp_Pat_id = currPatient.cp_pat_id
  patientQuestion.patient_id = currPatient._id
  patientQuestion.status_message = 'Question Sent'
  patientQuestion.updated_by = 'web'
  patientQuestion.updated_at = new Date()
  patientQuestion.pharmacy = pharmacyDetail.code
  patientQuestion.docType = 'patient_question'
  patientQuestion.email = currPatient.email
  patientQuestion.question = question
  patientQuestion.submitDate = new Date()
  patientQuestion.sent = false
  patientQuestion.pharmKey = pharmKey
  patientQuestion.patKey = patKey

  return patientQuestion
}

//const sendEmailOld = (askPharmacist, pharmacyDetail, patEmail) =>
  //fetch(
    //process.env.REACT_APP_API + '/email',
    //{ method: 'POST',
      //headers: {'Content-type': 'application/json',
      //body: askPharmacist.patient_message,
      //pharmEmail: pharmacyDetail.askEmail,
      //patEmail: patEmail,
      //Authorization: `Bearer ${process.env.REACT_APP_JWT}`}
  //}).then(console.log("sendEmail"))

const sendEmail = (askPharmacist, pharmacyDetail, sendLink) =>
  fetch(
    process.env.REACT_APP_API + '/changePassEmail',
    { method: 'POST',
      headers: {'Content-type': 'application/json',
      body: 'You have received a question! Please click this link to view and answer: <p><a href= ' + sendLink + '>Click here to answer the patients question.</a>',
      userEmail: pharmacyDetail.askEmail,
      subject: 'Ask A Pharmacist',
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`}
    })//.then(console.log("sendEmail"))

  const debug = (props) => {
      /*console.log(JSON.parse(localStorage.getItem('user')))*/
      /*console.log(testThis('grx_store2')) /* Tests Email */
      //console.log(props)
    }

class AskPharmacist extends Component {
  render() {
    const props = this.props

    return(

      <Popup className="br3"
              trigger={<button className="btn btn-large btn-primary" >
                <FontAwesome className="mr1" name="user" ></FontAwesome>
                Ask a Pharmacist
              </button>
                      }

              modal
              closeOnDocumentClick
      >

      {close => (
        <form className="ng-pristine ng-valid"
              onSubmit={ props.submit(close, props.askPharmacist, props.pharmacyDetail, props.validate, props.history) } >
              <div style={{ width: 650 }} className="modal fade ng-scope in"><div className="modal-header">

                  <h4 className="ng-binding">Ask your Pharmacist a question: </h4>
                </div>
                <div className="modal-body">
                  {/* ngRepeat: alert in alerts */}
                    <div className="control-group">
                      <label style={{ fontSize: 16 }} className="label-control">Response Email - {this.props.validate.email}</label>
                    </div>

                    <div className="control-group">
                      <label style={{ fontSize: 16 }}  className="label-control">My Question:</label>
                      <div className="controls">
                        <textarea style={{ fontSize: 16, height: 150, paddingTop: 15, paddingBottom: 15 }}  className="span6 ng-pristine ng-valid" type="textarea"
                        value={ pathOr('', ['askPharmacist', 'patient_message'], props) }
                               onChange={props.setPatientMessage} />
                      </div>
                    </div>

                </div>
              <div className="modal-footer">
                <button className="btn btn-primary" >Submit</button>
                <a className="btn btn-warning"
                   onClick={() => { close() }}
                     >Cancel</a>
              </div>
              </div>
          </form>
      )}

      </Popup>

    )
  }
}

const mapStateToProps = (state) => ({
  validate: state.validate,
  askPharmacist: state.askPharmacist,
  pharmacyDetail: state.pharmacyDetail,
  patientQuestion: state.patientQuestion
})
const mapActionsToProps = (dispatch) => ({
  submit: (close, askPharmacist, pharmacyDetail, currPatient, validate) => (e) => {
    e.preventDefault()
    dispatch({ type: 'SUBMIT_QUESTION', payload: buildQuestion(pharmacyDetail, currPatient, askPharmacist.patient_message) })
    var sendLink = 'https://' + pharmacyDetail.code + '.refillrequest.com/questions?code="' + pharmKey + '"'
    sendEmail(askPharmacist, pharmacyDetail, sendLink)
      .catch(err => console.log(err))
    close()
  },
  setPharmacist: (e) => dispatch({ type: 'SET_PHARMACIST_NAME', payload: e.target.value }),
  setPatientMessage: (e) => dispatch({ type: 'SET_PATIENT_MESSAGE', payload: e.target.value }),
  resetEmail: () => dispatch({ type: 'RESET_EMAIL' })
})
const connector = connect(mapStateToProps, mapActionsToProps)


export default connector(AskPharmacist)
