
import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css'
import './App.css'
import LandingPage from './pages/landingPage'
import Login from './pages/login'
import Register from './pages/register'
import ProfilePage from './pages/profiles'
import Reset from './pages/reset'
import Reset2 from './pages/reset2'
import Questions from './pages/questions'
import Answers from './pages/answers'


class App extends Component {


  render() {
    return (


      <BrowserRouter>
        <div>
          <main>
            <Route exact path='/' component={LandingPage} />
            <Route path='/signin' component={Login} />
            <Route path='/signup' component={Register} />
            <Route path='/profiles' component={ProfilePage} />
            <Route path='/reset' component={Reset} />
            <Route path='/resetpassword' component={Reset2} />
            <Route path='/questions' component={Questions} />
            <Route path='/answers' component={Answers} />
          </main>
        </div>
      </BrowserRouter>


    )
  }
}

export default App
