import React from 'react'
import FontAwesome from 'react-fontawesome'

const Header = () => {
  return(

    <header className="sans-serif">
        <div className="flex items-center bg-black-80 pl3 pr3">
            <h3>
              <a className="hover-white fw1 white-70 no-underline" href='/'>Refill Request</a>
            </h3>

            <div className="pl3 flex-auto">
              <span className="f4 db black-70"></span>
            </div>

            <div>
              <a className="hover-white fw1 f6 dib v-mid white-70 ph1 pv2 no-underline" href="/signin">
                <FontAwesome className="ph3" name="lock" size="lg" ></FontAwesome>
                Login </a>
              <span className="v-mid ph1 white-70"></span>
              <a className="hover-white fw1 f6 dib v-mid white-70 ph3 pv2 no-underline" href="/signup">
                <FontAwesome className="ph3" name="sign-in" size="lg" ></FontAwesome>
                Register </a>
              <span className="v-mid ph3 white-70"></span>
            </div>
        </div>
    </header>

  )
}

export default Header
