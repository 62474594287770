import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import Popup from 'reactjs-popup'
import { pathOr } from 'ramda'

var updateAddress

const updateProfilePatient = (patient, pharmacy) =>
  fetch(process.env.REACT_APP_API + '/' + pharmacy + '/patient/' + patient._id, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify(patient)
  })

  /*WHY DOES console.log FIX THIS?*/
  const getProfilePatient = (patient, pharmacy) =>
    fetch(process.env.REACT_APP_API + '/' + pharmacy + '/patient/' + patient, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT}`
      }
    })



class changeProfile extends Component {

  render() {
    const props = this.props

    return (

      <Popup className="br3"
              trigger={<button className="btn btn-large">
                          <FontAwesome className="mr1" name="user" ></FontAwesome>
                          Update Information
                        </button>
                      }
              modal
              closeOnDocumentClick
      >

      {close => (
        <form className="ng-pristine ng-valid"
              onSubmit={ props.submit(close, props.currentPatient, props.pharmacyDetail, props.history) } >

            <div className="modal fade ng-scope in"><div className="modal-header">

                <h4 className="ng-binding">Change Patient Address for {props.currentPatient.first_name} {props.currentPatient.last_name}</h4>
              </div>
              <div className="modal-body">
                {/* ngRepeat: alert in alerts */}
                  <div className="control-group">
                    <label style={{ fontSize: 16 }} className="label-control">Home Phone</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }} className="span4 ng-pristine ng-valid" type="text" value={ pathOr('', ['currentPatient', 'home_phone'], props) }
                             onChange={props.resetPhone}/>
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">Email</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid ng-valid-email" type="email" value={ pathOr('', ['currentPatient', 'email'], props) }
                             onChange={props.resetEmail} />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">Home Address</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text" value={ pathOr('', ['currentPatient', 'home_address_line_1'], props) }
                             onChange={props.resetHomeAddrLine1} />
                    </div>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text" value={ pathOr('', ['currentPatient', 'home_address_line_2'], props) }
                          onChange={props.resetHomeAddrLine2}  />
                    <div className="controls">
                    </div>
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text" value={ pathOr('', ['currentPatient', 'home_address_line_3'], props) }
                             onChange={props.resetHomeAddrLine3}  />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">City</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text" value={ pathOr('', ['currentPatient', 'home_address_city'], props) }
                             onChange={props.resetHomeCity}  />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">State</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text" value={ pathOr('', ['currentPatient', 'home_address_state'], props) }
                             onChange={props.resetHomeState}  />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">Zip</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text" value={ pathOr('', ['currentPatient', 'home_address_zip'], props) }
                             onChange={props.resetHomeZip}  />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">Shipping Address</label>
                    <div className="help-block">
                    </div>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text"
                      value={ pathOr('', ['currentPatient', 'ship_address_line_1'], props) }
                             onChange={props.resetShipAddrLine1} />
                    <div className="controls">
                    </div>
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text"
                      value={ pathOr('', ['currentPatient', 'ship_address_line_2'], props) }
                            onChange={props.resetShipAddrLine2} />
                   <div className="controls">
                   </div>
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text"
                      value={ pathOr('', ['currentPatient', 'ship_address_line_3'], props) }
                             onChange={props.resetShipAddrLine3} />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">City</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text"
                      value={ pathOr('', ['currentPatient', 'ship_address_city'], props) }
                             onChange={props.resetShipCity} />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">State</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text"
                      value={ pathOr('', ['currentPatient', 'ship_address_state'], props) }
                             onChange={props.resetShipState} />
                    </div>
                  </div>
                  <div className="control-group">
                    <label style={{ fontSize: 16 }}  className="label-control">Zip</label>
                    <div className="controls">
                      <input style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }}  className="span4 ng-pristine ng-valid" type="text"
                      value={ pathOr('', ['currentPatient', 'ship_address_zip'], props) }
                             onChange={props.resetShipZip} />
                    </div>
                  </div>

              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" >Submit</button>
                <button className="btn btn-warning"
                   onClick={() => { close() }}
                     >Cancel</button>
              </div>
            </div>

          </form>
      )}

      </Popup>

    )
  }
}

const mapStateToProps = (state) => ({
  pharmacyDetail: state.pharmacyDetail,
  validate: state.validate,
  currentPatient: state.currentPatient
})
const mapActionsToProps = (dispatch) => ({
  submit: (close, profilePatient, pharmacyDetail, history) => (e) => {
    profilePatient.updated_by = 'web'
    e.preventDefault()
    //updates patient data in Couchdb
    updateProfilePatient(profilePatient, pharmacyDetail.code)
      .then( res => res.json())
      .then(patient =>  {
        getProfilePatient(profilePatient._id, pharmacyDetail.code)
          .then(res => res.json())
          .then(updatedPatient => dispatch({ type: 'SET_PATIENT', payload: updatedPatient }))
      })
    close()
  },
  resetPhone: (e) => dispatch({ type: 'RESET_PHONE', payload: e.target.value }),
  resetEmail: (e) => dispatch({ type: 'RESET_EMAIL', payload: e.target.value }),
  resetHomeAddrLine1: (e) => dispatch({ type: 'RESET_HOME_ADDR_LINE_1', payload: e.target.value }),
  resetHomeAddrLine2: (e) => dispatch({ type: 'RESET_HOME_ADDR_LINE_2', payload: e.target.value }),
  resetHomeAddrLine3: (e) => dispatch({ type: 'RESET_HOME_ADDR_LINE_3', payload: e.target.value }),
  resetHomeCity: (e) => dispatch({ type: 'RESET_HOME_CITY', payload: e.target.value }),
  resetHomeState: (e) => dispatch({ type: 'RESET_HOME_STATE', payload: e.target.value }),
  resetHomeZip: (e) => dispatch({ type: 'RESET_HOME_ZIP', payload: e.target.value }),
  resetShipAddrLine1: (e) => dispatch({ type: 'RESET_SHIP_ADDR_LINE_1', payload: e.target.value }),
  resetShipAddrLine2: (e) => dispatch({ type: 'RESET_SHIP_ADDR_LINE_2', payload: e.target.value }),
  resetShipAddrLine3: (e) => dispatch({ type: 'RESET_SHIP_ADDR_LINE_3', payload: e.target.value }),
  resetShipCity: (e) => dispatch({ type: 'RESET_SHIP_CITY', payload: e.target.value }),
  resetShipState: (e) => dispatch({ type: 'RESET_SHIP_STATE', payload: e.target.value }),
  resetShipZip: (e) => dispatch({ type: 'RESET_SHIP_ZIP', payload: e.target.value })
})
const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(changeProfile)
