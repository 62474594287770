import React from 'react'
import FontAwesome from 'react-fontawesome'
import Popup9 from '../pages/profile/changeAct'

const Header = () => {
  return(

    <header className="sans-serif">
        <div className="flex items-center bg-black-80 pl3 pr3">
            <h3>
              <a className="hover-white fw1 white-70 no-underline" href='/'>Refill Request</a>
            </h3>

            <div className="pl3 flex-auto">
              <span className="f4 db black-70"></span>
            </div>

            <div>
              <Popup9></Popup9>
              <a className="hover-white fw1 f6 dib v-mid white-70 ph3 pv2 no-underline" href="/">
                <FontAwesome className="ph3" name="unlock" size="lg" ></FontAwesome>
                Logout </a>
              <span className="v-mid ph3 white-70"></span>
            </div>
        </div>
    </header>

  )
}

export default Header
