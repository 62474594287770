import { createStore, combineReducers } from 'redux'
import { set, lensProp, append, uniq, without, dissoc, slice, takeWhile } from 'ramda'
//import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


{/* Pulls url, strips out https:// and the '-' along with anything after that */}
{/*  Should just leave the pharmacy name; the '-' will need to be updated to '.' for prod */}
const href = window.location.href
const index = href.indexOf('/')
const newHref = slice(index + 2, 100, href)
var pharmacyHref = takeWhile(x => x !== '.', newHref)

if (pharmacyHref === 'localhost:3000') {
  pharmacyHref = 'womensinternational'
}


//const pharmacy = (state='grx_womensinternational', action) => {
//const pharmacy = (state='grx_womensinternationaltest', action) => {
//const pharmacy = (state = 'grx_store1', action) => {
const pharmacy = (state = 'grx_' + pharmacyHref, action) => {


  switch(action.type) {
    case '':
      return action.payload
    default:
      return state
  }
}


const initialState = `
{
   _id: "template",
   _rev: "15-76c6ab423d3dc1f9b706226be7a850f7",
   send_notification: true,
   updated_by: "pharmacy",
   confirmation: "Web Refill Request Confirmation\n\nThis message is a confirmation that your requested web refill order has been submitted to Test Pharmacy (WIP).\n\nWe appreciate you choosing Test Pharmacy as your resource for prescription medications.\n\nI set email notification to TRUE.\n\nBest Wishes,\nTest Pharmacy Staff",
   description: "<p>Hello World</p>",
   confirmationSubject: "Your Order was Received",
   type: "pharmacy",
   customCss: "Custom CSS\n.add_to_order {\n  background-color: #8fbc8f;\n  background-image: linear-gradient(to bottom, #8fbc8f, #8fbc8f);\n  color: #fff;\n}\n\n.add_to_order:hover,\n.add_to_order:focus,\n.add_to_order:active,\n.add_to_order.active,\n.add_to_order.disabled,\n.add_to_order[disabled] {\n  background-color: lightgreen;\n  color: #333333;\n  background-color: #90EE90;\n}\n    ",
   profileTop: "<p>&nbsp<p>",
   landingPage: "<div><h2>Welcome to The Web Refill System </h2>\n<p>Now that you have registered your account, it is time to add personal profile information.</p></div>"
}
`

const pharmacyDetail = (state=initialState, action) => {
  switch (action.type) {
    case 'SET_PHARMACY':
      return action.payload
    default:
      return state
  }
}

const searchID = (state=0, action) => {
  switch (action.type) {
    case 'SET_SEARCH_ID':
      return action.payload
    case 'REMOVE_SEARCH_ID':
      return 0
    default:
      return state
  }
}

const valUser = (state=[], action) => {
  switch(action.type) {
    case 'SET_VALUSER':
      return action.payload
    default:
      return state
  }
}

const profilePatients = (state=[], action) => {
  switch(action.type) {
    case 'SET_PATIENTS':
      return uniq(append(action.payload, state))
    case 'REMOVE_PATIENT':
      return without( [action.payload], state)
    default:
      return state
  }
}

const currentPatientId = (state=[], action) => {
  switch(action.type) {
    case 'SET_CURRENT_PATIENT_ID':
      return set(lensProp('_id'), action.payload, state)
    default:
      return state
  }
}

const currentPatient = (state=[], action) => {
  switch(action.type) {
    case 'SET_PATIENT':
      return action.payload
    case 'RESET_PHONE':
      return set(lensProp('home_phone'), action.payload, state)
    case 'RESET_EMAIL':
      return set(lensProp('email'), action.payload, state)
    case 'RESET_HOME_ADDR_LINE_1':
      return set(lensProp('home_address_line_1'), action.payload, state)
    case 'RESET_HOME_ADDR_LINE_2':
      return set(lensProp('home_address_line_2'), action.payload, state)
    case 'RESET_HOME_ADDR_LINE_3':
      return set(lensProp('home_address_line_3'), action.payload, state)
    case 'RESET_HOME_CITY':
      return set(lensProp('home_address_city'), action.payload, state)
    case 'RESET_HOME_STATE':
      return set(lensProp('home_address_state'), action.payload, state)
    case 'RESET_HOME_ZIP':
      return set(lensProp('home_address_zip'), action.payload, state)
    case 'RESET_SHIP_ADDR_LINE_1':
      return set(lensProp('ship_address_line_1'), action.payload, state)
    case 'RESET_SHIP_ADDR_LINE_2':
      return set(lensProp('ship_address_line_2'), action.payload, state)
    case 'RESET_SHIP_ADDR_LINE_3':
      return set(lensProp('ship_address_line_3'), action.payload, state)
    case 'RESET_SHIP_CITY':
      return set(lensProp('ship_address_city'), action.payload, state)
    case 'RESET_SHIP_STATE':
      return set(lensProp('ship_address_state'), action.payload, state)
    case 'RESET_SHIP_ZIP':
      return set(lensProp('ship_address_zip'), action.payload, state)
    default:
      return state
  }
}

const allPatients = (state=[], action) => {
  switch(action.type) {
    case 'SET_ALL_PATIENTS':
      return action.payload
    default:
      return state
  }
}

const searchPatient = (state=[], action) => {
  switch(action.type) {
    case 'SET_SEARCH_PATIENTS':
      return action.payload
    case 'SET_SEARCH_ID':
      return set(lensProp('search_id'), action.payload, state)
    case 'RESET_SEARCH':
      return []
    default:
      return state
  }
}

const initialProfile = {addNewProfile: true, removeProfile: false, updateUser: false}

const addProfile = (state=initialProfile, action) => {
  switch(action.type) {
    case 'ADD_PROFILE':
      return action.payload
    case 'ADD_PROFILE_KEY':
      return set(lensProp('key'), action.payload.toLowerCase(), state)
    case 'REMOVE_PROFILE_KEY':
      return dissoc('key', state)
    case 'PROFILE_FIRST_NAME':
      return set(lensProp('first_name'), action.payload, state)
    case 'PROFILE_LAST_NAME':
      return set(lensProp('last_name'), action.payload, state)
    case 'PROFILE_BIRTH_MONTH':
      return set(lensProp('birth_month'), action.payload, state)
    case 'PROFILE_BIRTH_YEAR':
      return set(lensProp('birth_year'), action.payload, state)
    case 'PROFILE_SSN4':
      return set(lensProp('ssn4'), action.payload, state)
    case 'PROFILE_ZIP':
      return set(lensProp('zip'), action.payload, state)
    case 'RESET_PROFILE':
      return initialProfile
    default:
      return state
  }
}


const validate = (state=[], action) => {
  switch(action.type) {
    case 'SET_VALIDATION':
      return action.payload
    case 'VALIDATE_FULLNAME':
      return set(lensProp('fullname'), action.payload, state)
    case 'VALIDATE_EMAIL':
      return set(lensProp('email'), action.payload, state)
    case 'SET_VAL_EMAIL':
      return action.payload
    case 'VALIDATE_USERNAME':
      return set(lensProp('name'), action.payload, state)
    case 'VALIDATE_PASSWORD':
      return set(lensProp('password'), action.payload, state)
    case 'CONFIRM_PASSWORD':
      return set(lensProp('confirmPassword'), action.payload, state)
    case 'RESET_VALIDATION':
      return { fullname: '', email: '', name: '', password: '', confirmPassword: '' }
    default:
      return state
  }
}

const patientComments = (state=[], action) => {
  switch(action.type) {
    case 'SET_COMMENTS':
      return set(lensProp('comments'), action.payload, state)
    case 'RESET_COMMENTS':
      return state=[]
    default:
      return state
  }
}

const patientShipping = (state=[], action) => {
  switch(action.type) {
    case 'SET_SHIP_OPTION':
      return set(lensProp('shipDesc'), action.payload, state)
    default:
      return state
  }
}

const orderPayment = (state=[], action) => {
  switch (action.type) {
    case 'SET_PMT_OPTION':
      return set(lensProp('pmtDesc'), action.payload, state)
    default:
      return state
  }
}

const initialOrder = {getAllOrders: false}

const patientOrder = (state=initialOrder, action) => {
  switch (action.type) {
    case 'RESET_ORDERS':
      return state = initialOrder
    case 'SUBMIT_ORDER':
      return action.payload
    default:
      return state
  }
}

const initialQuestion = {getAllQuestions: false, sent: ''}

const patientQuestion = (state=initialQuestion, action) => {
  switch (action.type) {
    case 'RESET_QUESTION':
      return state = initialQuestion
    case 'SUBMIT_QUESTION':
      return action.payload
    default:
      return state
  }
}

const patientOrderMeds = (state=[status, false], action) => {
  switch(action.type) {
    case 'SET_ITEMS':
      //console.log('SET_ITEMS')
      return uniq(append(action.payload, state))
    case 'SET_STATUS':
      //console.log('SET_STATUS')
      return !action.payload
    case 'SET_ALL_ITEMS':
      //console.log('SET_ALL_ITEMS')
      return action.payload
    case 'REMOVE_MED':
      //console.log('REMOVE_MED')
      return without([action.payload], state)
    case 'RESET_MEDS':
      //console.log('RESET_MEDS')
      return state = {status: false}
    default:
      return state
  }
}

const allOrders = (state=[], action) => {
  switch(action.type) {
    case 'SET_MOST_RECENT_PAT_ORDER':
      return action.payload
    case 'RESET_MOST_RECENT_PAT_ORDER':
      return state={}
    case 'SET_ALL_ORDERS':
      return action.payload
    default:
      return state
  }
}

const curPatOrders = (state=[], action) => {
  switch(action.type) {
    case 'SET_ALL_PAT_ORDERS':
      return action.payload
    default:
      return state
  }
}

const mostRecentPatOrder = (state=[], action) => {
  switch(action.type) {
    case 'SET_MOST_RECENT_PAT_ORDER':
      return action.payload
    default:
      return state
  }
}

const status = (state = 0, action) => {
  switch(action.type) {
    case 'SET_STATUS':
      return action.payload
    default:
      return state
  }
}

const askPharmacist = (state={}, action) => {
  switch(action.type) {
    case 'SET_EMAIL':
      return action.payload
    case 'RESET_EMAIL':
      return state= {}
    case 'SET_PHARMACIST_NAME':
      return set(lensProp('pharmacist_name'), action.payload, state)
    case 'SET_PATIENT_MESSAGE':
      return set(lensProp('patient_message'), action.payload, state)
    default:
      return state
  }
 }

 const question = (state=[], action) => {
   switch(action.type) {
     case 'SET_QUESTION':
       return action.payload
     case 'SET_ANSWER':
       return set(lensProp('answer'), action.payload, state)
     case 'SET_ANSWERED_FLAG':
       return set(lensProp('answered_flag'), action.payload, state)
     case 'SET_EXPIRED_FLAG':
       return set(lensProp('expired_flag'), action.payload, state)
    case 'SET_UPDATED_AT':
       return set(lensProp('updated_at'), action.payload, state)
     default:
       return state
   }

 }

const persistConfig = {
  key: 'root',
  storage: storage
}

const store = createStore(
  combineReducers({
      pharmacy,
      pharmacyDetail,
      searchID,
      valUser,
      profilePatients,
      addProfile,
      validate,
      currentPatientId,
      currentPatient,
      allPatients,
      searchPatient,
      patientOrder,
      patientQuestion,
      patientComments,
      patientShipping,
      patientOrderMeds,
      orderPayment,
      status,
      askPharmacist,
      question,
      allOrders,
      curPatOrders,
      mostRecentPatOrder,
    })
  )

export default store
