import React, { Component } from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import ReactHtmlParser from 'react-html-parser'
import htmlclean from 'htmlclean'
import Popup from 'reactjs-popup'
import { map, pathOr, omit, filter, pick, insert } from 'ramda'


const buildOrder = (allMeds, user, pharmacyDetail, currPatient, comments, shipping, payment, validate) => {
  var patientOrder = {}

  patientOrder.cp_Pat_id = currPatient.cp_pat_id
  patientOrder.patient_id = currPatient._id
  patientOrder.items = allMeds
  patientOrder.status_message = 'Order Received'
  patientOrder.updated_by = 'web'
  patientOrder.updated_at = pharmacyDetail.updated_at
  patientOrder.order_number = ''
  patientOrder.user = user
  patientOrder.pharmacy = pharmacyDetail.code
  patientOrder.docType = 'patient_order'
  patientOrder.order_id = 0
  patientOrder.pharmacy_message = ''
  patientOrder.email = currPatient.email
  patientOrder.comments = comments.comments
  patientOrder.shipping = filter(ship => ship.description === shipping.shipDesc, pharmacyDetail.shipping_options).pop()
  patientOrder.payment = filter(pmt => pmt.cc_last4 === payment.pmtDesc, currPatient.cc_tokens).pop()
  patientOrder.submitDate = new Date()

  return patientOrder
}

const sendEmail = (validate, pharmacyDetail) =>
  fetch(
    process.env.REACT_APP_API + '/confirmationEmail',
    { method: 'POST',
      headers: {'Content-type': 'application/json',
      body: pharmacyDetail.confirmation,
      patEmail: validate,
      subject: pharmacyDetail.confirmationSubject,
      Authorization: `Bearer ${process.env.REACT_APP_JWT}`}
  }).then(console.log("sendEmail"))

class Checkout extends Component {
  render() {
    //console.log('Checkout Rendering')
    const props = this.props
    var addCCtokens = {
      "cc_last4": "- Select Payment -",
      "cc_type": "Bitcoin",
      "cc_token_id": "0001"
    }


        /*console.log('checkoutMessage is, ', htmlclean(props.pharmacyDetail.checkoutMessage) )
    console.log('checkoutMessage wHtmlParser & clean is, ', ReactHtmlParser( htmlclean(props.pharmacyDetail.checkoutMessage) ) )*/

    return (

      <Popup trigger={  <button className="btn" disabled={!props.patientOrderMeds.length}>
                          <FontAwesome className="mr1" name="shopping-cart" ></FontAwesome>
                          Check Out
                        </button>
                      }
             modal
             closeOnDocumentClick
      >
        { close => (

          <form onSubmit={
                  props.submit(close, props.patientOrderMeds, omit(['salt'], props.validate), props.pharmacyDetail,
                  props.currentPatient, props.patientComments, props.patientShipping, props.orderPayment, props.currentPatient.scripts, props.validate.email) }>

            <div className="modal fade ng-scope in"><div className="modal-header">
              <h4 className="ng-binding">Order for {props.currentPatient.first_name} {props.currentPatient.last_name}</h4>
            </div>
            <div className="modal-body">
              <div>
                <div ng-bind-html="checkoutTop" className="ng-binding" />
              </div>

              {/* Check to see if checkoutMessage exists in pharmacy Couch database */}
               <div>
                 {props.pharmacyDetail.checkoutMessage
                    ?  ReactHtmlParser( htmlclean(props.pharmacyDetail.checkoutMessage) )
                    :  <label></label>
                 }
               </div>

              <div className="pull-right">
                <label>Special Instructions</label>
                <textarea style={{ fontSize: 16, paddingTop: 15, paddingBottom: 15 }} className="ng-pristine ng-valid" type="text"
                       value={ pathOr('', ['patientComments', 'comments'], props) }
                       onChange={ props.setComments }
                   />

                <label>Shipping Address on File:</label>
                  { props.currentPatient.ship_address_line_1 === ""
                    ? <div className="ng-binding  ng-hide">
                          <h5>{props.currentPatient.home_address_line_1}</h5>
                          <h5>{props.currentPatient.home_address_line_2}</h5>
                          <h5>{props.currentPatient.home_address_line_3}</h5>
                          <h5>{props.currentPatient.home_address_city},  {props.currentPatient.home_address_state}  {props.currentPatient.home_address_zip}</h5>
                      </div>
                    :
                      <div className="ng-binding ng-hide">
                        <h5>{props.currentPatient.ship_address_line_1}</h5>
                        <div className="ng-binding ng-hide">
                          <h5>{props.currentPatient.ship_address_line_2}</h5>
                        </div>
                        <div className="ng-binding ng-hide">
                          <h5>{props.currentPatient.ship_address_line_3}</h5>
                        </div>
                          <h5>{props.currentPatient.ship_address_city},  {props.currentPatient.ship_address_state}  {props.currentPatient.ship_address_zip}</h5>
                      </div>
                  }
              </div>

              <label>Shipping</label>
                <select ng-model="order.shipping"
                        className="ng-pristine ng-valid"
                        value={ pathOr('', ['patientShipping', 'shipDesc'], props) }
                        onChange={ props.addShippingOption }
                >
                  <option key='default' value={0}> - Select Shipping Method - </option>
                  { map(shipOption =>
                      <option key={shipOption.code} value={shipOption.index}>{shipOption.description}
                      </option>
                    , props.pharmacyDetail.shipping_options) }
                </select>

                <label>Payment</label>
                <small>(For updating payment method please call pharmacy.)</small>
                <select  ng-model="order.payment" className="ng-pristine ng-valid"
                         value={ pathOr('', ['orderPayment', 'pmtDesc'], props) }
                         onChange={ props.addPmtOption }
                >
                  { map(pmtOption =>
                    <option key={pmtOption.cc_token_id} value={pmtOption.index}>{pmtOption.cc_last4}
                    </option>
                        , props.currentPatient.cc_tokens) }
    {/* <option value >- Select Payment -</option> */}
                </select>

              <label>Medications</label>
              <ul className="nav nav-stacked">
                {/* ngRepeat: script in order.items */}
                { props.patientOrderMeds
                  ? map(order =>  <li key={order.scriptno} style={{marginBottom: 5}} ng-repeat="script in order.items" className="ng-scope">
                                    <div className="pull-right" ng-click="remove($index)"></div>
                                    <h6 className="ng-binding">{order.scriptno} {order.drug_name}
                                      <a onClick={ e => props.removeMed(order) } >
                                        <FontAwesome className="ml4" size="lg" name="trash" ></FontAwesome>
                                      </a>
                                    </h6>
                                  </li>
                    , props.patientOrderMeds)
                  :               <li style={{marginBottom: 5}} ng-repeat="script in order.items" className="ng-scope">
                                    <div className="pull-right" ng-click="remove($index)"><FontAwesome name="trash" ></FontAwesome></div>
                                    <h6 className="ng-binding">No Medications selected!</h6>
                                  </li>
                }
              </ul>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary"
                      >Submit</button>
              <button className="btn btn-warning"
                      onClick={() => {
                        this.props.resetMeds(props.currentPatient.scripts)
                        close() }}
                >Cancel</button>
            </div>
          </div>
        </form>

        )}

      </Popup>
    )
  }
}

const mapStateToProps = (state) => ({
  pharmacyDetail: state.pharmacyDetail,
  currentPatient: state.currentPatient,
  patientOrder: state.patientOrder,
  patientOrderMeds: state.patientOrderMeds,
  validate: state.validate,
  patientComments: state.patientComments,
  patientShipping: state.patientShipping,
  orderPayment: state.orderPayment
})
const mapActionsToProps = (dispatch) => ({
  submit: (close, allMeds, user, pharmacyDetail, currPatient, comments, shipping, pmt, allScripts, validateEmail) => (e) => {
    e.preventDefault()
    if (!shipping.shipDesc){
      alert('Please select a shipping method')
    } else {
      dispatch({ type: 'SUBMIT_ORDER', payload: buildOrder(allMeds, user, pharmacyDetail, currPatient, comments, shipping, pmt) })
      map(script => (script.status === true) ? script.status = false
                                              : script.status
        , allScripts)

      dispatch({ type: 'RESET_MEDS' })
      dispatch({ type: 'RESET_COMMENTS' })
      sendEmail(validateEmail, pharmacyDetail)
        .catch(err => console.log(err))
      alert('Your order has been submitted!')
      close()
    }
  },
  setComments: (e) => dispatch({ type: 'SET_COMMENTS', payload: e.target.value }),
  addShippingOption: (e) => dispatch({ type: 'SET_SHIP_OPTION', payload: e.target.value }),
  addPmtOption: (e) => dispatch({ type: 'SET_PMT_OPTION', payload: e.target.value }),
  removeMed: (med) => dispatch({ type: 'REMOVE_MED', payload: med }),
  resetMeds: (allScripts) => {
    map(script => (script.status === true) ? script.status = false
                                            : script.status
      , allScripts)
    dispatch({ type: 'RESET_MEDS' })
    dispatch({ type: 'RESET_COMMENTS' })
    dispatch({ type: 'RESET_ORDERS'})
  }
})
const connector = connect(mapStateToProps, mapActionsToProps)

export default connector(Checkout)
