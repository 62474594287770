import React from 'react'

const BasicButton = (props) => {
  return(
    <button
      onClick={props.onClick}
      style={ props.style }
      className='fw5 white f6 pv2 no-underline bg-animate hover-black ba pl2 pr2'
    >
      <span className="pl1 pr2">{props.children}</span>
    </button>
  )
}


export default BasicButton
